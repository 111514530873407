import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { FixedSizeList as List } from 'react-window';
import {
  Button,
  Chip,
  Divider,
  IconButton,
  SwipeableDrawer,
  Tooltip,
} from '@mui/material';
import Carousel from 'react-multi-carousel';
import housePlaceholder from '../../media/housePlaceholder.webp';
import {
  BedOutlined,
  CameraAltOutlined,
  CloseOutlined,
  DirectionsCarFilledOutlined,
  ShowerOutlined,
  SpaceDashboardOutlined,
} from '@mui/icons-material';
import PropertyDetailsDialog from '../SearchResults/PropertyDetailsDialog';
import SelectedPropertyMiniCard from '../Cards/SelectedPropertyMiniCard';
import { useDispatch, useSelector } from 'react-redux';
import { propertyValuesActions } from '../../store/propertyValues';
import {
  useGetPropertiesByGeometry,
  useGetPropertiesByMapBounds,
  useGetPropertyById,
} from '../../services/propertyService';
import * as turf from '@turf/turf';
import { markerValuesActions } from '../../store/markerValues';
import { useSearchParams } from 'react-router-dom';
import Lottie from 'react-lottie';
import cameraAnimation from '../../animations/camera.json';
import { useMeasure } from '@uidotdev/usehooks';

const responsive = {
  // superLargeDesktop: {
  //   // the naming can be any, depends on you.
  //   breakpoint: { max: 4000, min: 3000 },
  //   items: 1,
  // },
  // desktop: {
  //   breakpoint: { max: 3000, min: 1024 },
  //   items: 1,
  // },
  // tablet: {
  //   breakpoint: { max: 1024, min: 464 },
  //   items: 1,
  // },
  mobile: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

const iOS =
  typeof navigator !== 'undefined' &&
  /iPad|iPhone|iPod/.test(navigator.userAgent);

const SelectedPropertyDrawer = ({
  isPropertyDrawerOpen,
  setIsPropertyDrawerOpen,
  // selectedProperty,
  isExtraSmallDevice,
  map,
}) => {
  const [propertyDetailsDrawer, setPropertyDetailsDrawer] = useState(false);
  const [filteredPropertiesLength, setFilteredPropertiesLength] = useState(0);
  const [filteredList, setFilteredList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [nearPropertiesRef, { width, height }] = useMeasure();

  const { selectedProperty, selectedPropertyId } = useSelector(
    (state) => state.propertyValues
  );

  const { selectedSearchResult } = useSelector((state) => state.objectValues);

  const { mapZoom, mapBounds } = useSelector((state) => state.mapValues);

  const filters = {};
  Array.from(searchParams.entries()).forEach((item) => {
    filters[item[0]] = item[1];
  });

  const {
    propertiesByGeometry,
    isLoadingPropertiesByGeometry,
    isErrorPropertiesByGeometry,
  } = useGetPropertiesByGeometry(
    selectedSearchResult,
    filters,
    filters['action'] || 'rent',
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const { propertiesByMapBounds, isLoadingProperties, isErrorProperties } =
    useGetPropertiesByMapBounds(
      mapZoom,
      mapBounds,
      selectedSearchResult,
      filters,
      filters['action'] || 'rent',
      {
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        dedupingInterval: 2000,
      }
    );

  const dispatch = useDispatch();

  const calculateDistance = (coord1, coord2) => {
    const from = turf.point([coord1[1], coord1[0]]);
    const to = turf.point([coord2[1], coord2[0]]);
    return Math.round(turf.distance(from, to, { units: 'kilometers' }) * 1000);
  };

  const handleClosePropertyDetailsDrawer = () => {
    setPropertyDetailsDrawer(false);
  };

  const handleNearPropertiesSelected = (propertyItem) => {
    dispatch(propertyValuesActions.setSelectedProperty(propertyItem));
    dispatch(propertyValuesActions.setSelectedPropertyId(propertyItem._id));
    dispatch(
      markerValuesActions.setSelectedMarker({
        id: propertyItem._id,
        timestamp: Date.now(),
      })
    );

    map.eachLayer((layer) => {
      if (layer instanceof L.MarkerCluster) {
        layer.getAllChildMarkers().forEach((childMarker) => {
          if (childMarker.options.propertyId === propertyItem._id) {
            layer.spiderfy();
          }
        });
      }
    });
  };

  useEffect(() => {
    console.log('Width:', width);
    console.log('Height:', height);
  }, [width, height]);

  useEffect(() => {
    if (Array.isArray(propertiesByMapBounds)) {
      setFilteredPropertiesLength(propertiesByMapBounds.length);
    }

    if (Array.isArray(propertiesByGeometry)) {
      setFilteredPropertiesLength(propertiesByGeometry.length);
    }
  }, [propertiesByMapBounds, propertiesByGeometry]);

  const Row = ({ index, style }) => {
    let propertyItem;

    if (Array.isArray(propertiesByMapBounds)) {
      propertyItem = propertiesByMapBounds.sort(
        (a, b) => a.totalPrice - b.totalPrice
      )[index];
    }

    if (Array.isArray(propertiesByGeometry)) {
      propertyItem = propertiesByGeometry.sort(
        (a, b) => a.totalPrice - b.totalPrice
      )[index];
    }

    return (
      <div
        key={propertyItem?._id}
        style={{
          ...style,
          paddingLeft: '0.8rem',
          paddingRight: '0.8rem',
        }}
        onClick={() => handleNearPropertiesSelected(propertyItem)}
      >
        <SelectedPropertyMiniCard
          key={propertyItem?._id}
          propertyItem={propertyItem}
        />
      </div>
    );
  };

  if (!selectedProperty || selectedProperty.message) {
    return;
  }

  return (
    <div>
      <PropertyDetailsDialog
        handleClosePropertyDetailsDrawer={handleClosePropertyDetailsDrawer}
        propertyDetailsDrawer={propertyDetailsDrawer}
        isExtraSmallDevice={isExtraSmallDevice}
      />
      <SwipeableDrawer
        open={isPropertyDrawerOpen}
        anchor='left'
        onClose={() => {
          dispatch(propertyValuesActions.setSelectedProperty(null));
          dispatch(propertyValuesActions.setSelectedPropertyId(null));
          dispatch(markerValuesActions.setSelectedMarker(null));
          setIsPropertyDrawerOpen(false);
        }}
        variant='temporary'
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        keepMounted={false}
      >
        {selectedProperty && (
          <div className='flex relative flex-col w-screen md:w-96 h-full bg-slate-50'>
            <div className='flex absolute top-2 left-2 select-none z-[50]'>
              <Tooltip
                title={`${selectedProperty.images.length} ${
                  selectedProperty.images.length > 1 ? 'fotos' : 'foto'
                }`}
                enterDelay={500}
                leaveDelay={0}
                placement='right'
                arrow
              >
                <div className='flex gap-2 items-center justify-center bg-emerald-500 p-2 px-3 rounded-md shadow-lg'>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: false,
                      animationData: cameraAnimation,
                    }}
                    height={22}
                    width={22}
                    isStopped={true}
                  />
                  <h1 className='font-semibold text-white'>
                    {`${selectedProperty.images.length} ${
                      selectedProperty.images.length > 1 ? 'fotos' : 'foto'
                    }`}
                  </h1>
                </div>
              </Tooltip>
            </div>
            <div className='flex absolute top-3 right-2 select-none z-[50]'>
              <Tooltip
                title='Fechar'
                enterDelay={0}
                leaveDelay={0}
                placement='left'
                arrow
              >
                <div className='bg-slate-200 border border-gray-400 rounded-full shadow-lg hover:bg-gray-300 duration-500 mr-2'>
                  <IconButton
                    variant='contained'
                    sx={{ color: 'black' }}
                    size='small'
                    onClick={() => {
                      dispatch(propertyValuesActions.setSelectedProperty(null));
                      dispatch(
                        propertyValuesActions.setSelectedPropertyId(null)
                      );
                      dispatch(markerValuesActions.setSelectedMarker(null));
                      setIsPropertyDrawerOpen(false);
                    }}
                  >
                    <CloseOutlined />
                  </IconButton>
                </div>
              </Tooltip>
            </div>
            <div onTouchStart={(e) => e.stopPropagation()}>
              <Carousel
                responsive={responsive}
                infinite={true}
                slidesToSlide={1}
                className='w-full relative'
                swipeable
                draggable
              >
                {[...selectedProperty.images]
                  .sort((a, b) => {
                    if (a.isCover) return -1;
                    if (b.isCover) return 1;
                  })
                  .map((image) => (
                    <img
                      key={image.name}
                      src={image.url || housePlaceholder}
                      alt='property'
                      className='w-full h-48 object-fill'
                    />
                  ))}
              </Carousel>
            </div>
            <div className='flex flex-col h-16 items-center justify-center bg-slate-100'>
              <p className='text-black text-xl font-bold truncate'>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(selectedProperty.totalPrice)}
              </p>
              <p className='text-black text-sm truncate'>{`${
                selectedProperty.action === 'sale'
                  ? 'Preço:'
                  : 'Aluguel (R$/mês)'
              }`}</p>
            </div>
            <div className='flex flex-col p-2 gap-4 mb-6'>
              <div className='flex flex-col items-center'>
                <p className='text-base font-bold tracking-tight text-emerald-700 truncate'>
                  {`${selectedProperty.neighborhood}, ${selectedProperty.city}`}
                </p>
              </div>
              <div className='grid grid-cols-2 justify-between gap-2 bg-slate-100 border shadow-lg p-2'>
                <div className='flex items-center gap-1'>
                  <SpaceDashboardOutlined className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${selectedProperty.area} m²`}</p>
                </div>
                <div className='flex items-center gap-1'>
                  <BedOutlined className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${
                    selectedProperty.bedrooms
                  } ${
                    selectedProperty.bedrooms > 1 ? 'quartos' : 'quarto'
                  }`}</p>
                </div>
                <div className='flex items-center gap-1'>
                  <ShowerOutlined className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${
                    selectedProperty.bathrooms
                  } ${
                    selectedProperty.bathrooms > 1 ? 'banheiros' : 'banheiro'
                  }`}</p>
                </div>
                <div className='flex items-center gap-1'>
                  <DirectionsCarFilledOutlined className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${
                    selectedProperty.parkingSlots
                  } ${
                    selectedProperty.parkingSlots > 1 ? 'vagas' : 'vaga'
                  }`}</p>
                </div>
              </div>
              <div className='flex items-center justify-center'>
                <button
                  className='p-2 px-4 text-white font-semibold duration-150 bg-emerald-500 rounded-lg hover:text-black active:shadow-lg disabled:cursor-not-allowed disabled:bg-gray-300'
                  onClick={() => setPropertyDetailsDrawer(true)}
                >
                  Ver detalhes
                </button>
              </div>
            </div>
            <Divider variant='middle'>
              <Chip
                label={
                  <h1 className='font-semibold'>
                    Imóveis próximos: {filteredPropertiesLength}
                  </h1>
                }
                sx={{ backgroundColor: 'white', border: '1px solid #d3d3d3' }}
              />
            </Divider>
            {(propertiesByMapBounds || propertiesByGeometry) && (
              <div
                className='flex flex-col flex-grow gap-1 mt-4'
                ref={nearPropertiesRef}
              >
                {/* <div className='flex justify-center'>
                  <h1 className='text-lg font-bold'>{`Imóveis próximos (${filteredPropertiesLength})`}</h1>
                </div> */}

                {height && (
                  <div className='flex flex-col flex-grow overflow-y-auto'>
                    {selectedProperty && (
                      <List
                        height={height} // Adjust the height as needed
                        itemCount={filteredPropertiesLength}
                        itemSize={130} // Adjust the item size as needed
                        width={'100%'}
                      >
                        {Row}
                      </List>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </SwipeableDrawer>
    </div>
  );
};

export default SelectedPropertyDrawer;

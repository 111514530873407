import React, { useState } from 'react';
import { useGetAlerts } from '../services/alertService';
import MyAlertsCard from '../components/Cards/MyAlertsCard';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AddAlert } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { drawerValuesActions } from '../store/drawerValues';

const MyAlertsPage = () => {
  const { alertsList, isLoading, isError } = useGetAlerts({
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const { userId } = useSelector((state) => state.loginValues);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);

  if (isLoading) {
    // setOpen(true);
    return (
      <div>
        <Backdrop
          sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
          open={open}
          //   onClick={handleClose}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </div>
    );
  }

  if (isError) {
    return <div>Erro ao carregar os alertas</div>;
  }

  if ((alertsList && alertsList.message) || !userId) {
    return (
      <div className='flex flex-col flex-grow w-full items-center justify-center gap-4 h-full'>
        <h1 className='font-bold  text-center text-lg my-6 px-4'>
          Faça login para criar alertas
        </h1>
        <button
          className='bg-emerald-400 font-semibold px-4 py-2 rounded-md hover:bg-emerald-500'
          onClick={() => {
            dispatch(drawerValuesActions.setLoginDrawer(true));
          }}
        >
          Login
        </button>
      </div>
    );
  }

  if (alertsList && alertsList.length === 0) {
    return (
      <div className='flex flex-col justify-center items-center h-full text-center p-8 gap-8'>
        <h1 className='text-lg md:text-xl lg:text-2xl font-bold'>
          Você não possui alertas cadastrados
        </h1>
        <p className='text-sm md:text-base lg:text-lg'>
          Crie um alerta para receber notificações de imóveis que atendam aos
          seus critérios de busca.
        </p>
        <Button
          variant='contained'
          color='success'
          startIcon={<AddAlert />}
          onClick={() => navigate('/buscar')}
        >
          Criar alerta
        </Button>
      </div>
    );
  }

  return (
    <div className='flex flex-col justify-center items-center p-2 gap-4'>
      <h1 className='text-xl font-bold my-6'>Meus Alertas</h1>

      <div className=''>
        {!isLoading &&
          !isError &&
          alertsList &&
          alertsList.map((alert) => (
            <MyAlertsCard key={alert._id} item={alert} />
          ))}
      </div>
      {console.log(alertsList)}
    </div>
  );
};

export default MyAlertsPage;

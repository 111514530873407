import React, { useState, useEffect, useCallback, useRef } from "react";
import { Backdrop, Typography, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Lottie from "react-lottie";
import micAnimation from "../../animations/microphone.json";
import { toast } from "react-toastify";
import { createPortal } from "react-dom";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

const VoiceSearch = ({ onResult, setSearchText }) => {
  const recognition = useRef(null);
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState("");
  const [finalTranscript, setFinalTranscript] = useState("");
  const [micAnimationPaused, setMicAnimationPaused] = useState(true);
  const [response, setResponse] = useState(null);
  const [hasPermission, setHasPermission] = useState(null);
  const [permissionError, setPermissionError] = useState("");

  // Initialize recognition instance
  useEffect(() => {
    recognition.current = new SpeechRecognition();
    recognition.current.continuous = !isMobile;
    recognition.current.interimResults = true;
    recognition.current.lang = "pt-BR";

    return () => {
      if (recognition.current) {
        try {
          recognition.current.abort();
        } catch (error) {
          console.error("Error cleaning up recognition:", error);
        }
      }
    };
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: false,
    animationData: micAnimation,
  };

  const handleFinalTranscript = useCallback(
    async (text) => {
      setSearchText(text);
    },
    [setSearchText]
  );

  const stopListening = useCallback(() => {
    try {
      recognition.current?.stop();
    } catch (error) {
      console.error("Error stopping recognition:", error);
    }
    setIsListening(false);
    setMicAnimationPaused(true);
  }, []);

  // Function to auto-restart recognition on mobile
  const autoRestartRecognition = useCallback(() => {
    if (isMobile && isListening && recognition.current) {
      recognition.current.start();
    }
  }, [isListening]);

  useEffect(() => {
    if (!recognition.current) return;

    const handleResult = (event) => {
      let interimTranscript = "";
      let finalTranscriptPart = "";

      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscriptPart += transcript;
        } else {
          interimTranscript += transcript;
        }
      }

      setTranscript(interimTranscript);
      if (finalTranscriptPart !== "") {
        setFinalTranscript(finalTranscriptPart);
        // if (!isMobile) {
        stopListening();
        handleFinalTranscript(finalTranscriptPart);
        // }
      }
    };

    const handleError = (event) => {
      console.error("Speech recognition error:", event.error);
      setIsListening(false);
      setMicAnimationPaused(true);
    };

    const handleEnd = () => {
      setIsListening(false);
      setMicAnimationPaused(true);
    };

    recognition.current.onresult = handleResult;
    recognition.current.onerror = handleError;
    recognition.current.onend = handleEnd;

    return () => {
      if (recognition.current) {
        recognition.current.onresult = null;
        recognition.current.onerror = null;
        recognition.current.onend = null;
      }
    };
  }, [
    isListening,
    handleFinalTranscript,
    stopListening,
    autoRestartRecognition,
  ]);

  const requestMicrophonePermission = async () => {
    try {
      setPermissionError("");
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
      setHasPermission(true);
      return true;
    } catch (error) {
      console.error("Microphone permission denied:", error);
      toast.error(
        "O acesso ao microfone foi negado. Por favor, permita o acesso nas configurações do seu navegador.",
        {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
      if (error.name === "NotAllowedError") {
        setPermissionError(
          "O acesso ao microfone foi negado. Por favor, permita o acesso nas configurações do seu navegador."
        );
      } else if (error.name === "NotFoundError") {
        setPermissionError(
          "Nenhum microfone foi encontrado no seu dispositivo."
        );
      } else {
        setPermissionError(
          "Ocorreu um erro ao acessar o microfone. Por favor, tente novamente."
        );
      }
      setHasPermission(false);
      return false;
    }
  };

  const startListening = useCallback(async () => {
    if (!recognition.current) return;

    if (hasPermission === null || hasPermission === false) {
      const permitted = await requestMicrophonePermission();
      if (!permitted) return;
    }

    try {
      // First update UI state
      setIsListening(true);
      setMicAnimationPaused(false);
      setTranscript("");
      setFinalTranscript("");
      setResponse(null);

      // Then start recognition
      recognition.current.start();
    } catch (error) {
      console.error("Failed to start recognition:", error);
      stopListening();
    }
  }, [hasPermission, stopListening]);

  useEffect(() => {
    // Initialize recognition event handlers
    const recognitionInstance = recognition.current;

    if (!recognitionInstance) {
      return;
    }

    return () => {
      try {
        recognitionInstance.abort();
        stopListening();
      } catch (error) {
        console.error("Error cleaning up recognition:", error);
      }
    };
  }, [stopListening]);

  const handleClose = useCallback(() => {
    try {
      recognition.current?.abort();
      setIsListening(false);
      setMicAnimationPaused(true);
      setResponse(null);
      setTranscript("");
      setFinalTranscript("");
    } catch (error) {
      console.error("Error during close:", error);
    }
  }, []);

  const renderJsonResponse = (data) => {
    if (typeof data !== "object")
      return <Typography>{JSON.stringify(data)}</Typography>;

    return (
      <Box
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          p: 2,
          borderRadius: 2,
          maxWidth: "80%",
          overflow: "auto",
        }}
      >
        {Object.entries(data).map(([key, value]) => (
          <Box key={key} sx={{ mb: 1 }}>
            <Typography component="span" sx={{ color: "#8be9fd" }}>
              {key}:{" "}
            </Typography>
            <Typography component="span">
              {typeof value === "object"
                ? JSON.stringify(value, null, 2)
                : value.toString()}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <>
      <button
        onClick={startListening}
        className="p-2 rounded-full hover:bg-gray-50 transition-colors text-gray-500 hover:text-emerald-500 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
        aria-label="Voice search"
        onMouseEnter={() => !isListening && setMicAnimationPaused(false)}
        onMouseLeave={() => !isListening && setMicAnimationPaused(true)}
      >
        <div className="w-5 h-5">
          <Lottie
            options={defaultOptions}
            height={20}
            width={20}
            isStopped={micAnimationPaused}
          />
        </div>
      </button>

      {createPortal(
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: 99999,
            flexDirection: "column",
            gap: 2,
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.85)",
            backdropFilter: "blur(8px)",
          }}
          open={isListening}
          transitionDuration={300}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              color: "white",
              transition: "all 0.2s ease",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.15)",
                transform: "scale(1.05)",
              },
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              gap: 3,
              maxWidth: "600px",
              width: "90%",
              mx: "auto",
              p: 4,
              transition: "all 0.3s ease",
              animation: "fadeIn 0.3s ease-out",
            }}
          >
            {permissionError ? (
              <Typography
                variant="body1"
                sx={{
                  backgroundColor: "rgba(255,255,255,0.95)",
                  color: "error.main",
                  p: 3,
                  borderRadius: "12px",
                  maxWidth: "90%",
                  fontWeight: 500,
                  boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                  animation: "slideDown 0.3s ease-out",
                }}
              >
                {permissionError}
              </Typography>
            ) : (
              isListening && (
                <div className="animate-slideDown">
                  <div className="w-24 h-24 animate-pulse mb-6">
                    <Lottie options={defaultOptions} height={96} width={96} />
                  </div>
                  <Typography
                    variant="h5"
                    sx={{
                      color: "white",
                      fontWeight: 500,
                      textShadow: "0 2px 8px rgba(0,0,0,0.3)",
                      letterSpacing: "0.01em",
                    }}
                  >
                    {transcript || finalTranscript || "Ouvindo..."}
                  </Typography>
                </div>
              )
            )}
            {response && (
              <Box
                sx={{
                  mt: 3,
                  width: "100%",
                  animation: "slideDown 0.3s ease-out",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    mb: 2,
                    color: "white",
                    fontWeight: 500,
                    textShadow: "0 2px 8px rgba(0,0,0,0.3)",
                    letterSpacing: "0.01em",
                  }}
                >
                  Resultado:
                </Typography>
                {renderJsonResponse(response)}
              </Box>
            )}
          </Box>
        </Backdrop>,
        document.body
      )}
    </>
  );
};

export default VoiceSearch;

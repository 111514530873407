import React from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";

const ServicesPreviewCard = ({ icon, title }) => {
  return (
    <div className="w-[160px] h-[150px] p-4 rounded-xl bg-white hover:bg-gray-50 cursor-pointer duration-300 border border-gray-100 shadow-sm hover:shadow-lg group">
      <div className="flex flex-col h-full">
        <div className="flex-1 flex justify-center items-center group-hover:scale-110 transition-transform duration-300">
          <img src={icon} alt={title} />
        </div>
        <div className="mt-2">
          <div className="font-medium text-center text-sm text-gray-700 group-hover:text-emerald-600 transition-colors">
            {title}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesPreviewCard;

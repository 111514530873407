import useSWR from 'swr';
import { serverUrl } from '../config/config';

// Create a get method to fetch the favorites
export const getFavorites = async () => {
  try {
    const response = await fetch(`${serverUrl}/favorites`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

// Create a post method to add a favorite
export const postFavorites = async (propertyId) => {
  try {
    const response = await fetch(`${serverUrl}/favorites`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ propertyId }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

// Create a delete method to delete a favorite
export const deleteFavorite = async (propertyId) => {
  try {
    const response = await fetch(`${serverUrl}/favorites/${propertyId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    // const data = await response.json();
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const useGetFavorites = () => {
  const { data, error, isLoading, isValidating } = useSWR(
    `favorites`,
    getFavorites
  );

  return {
    favoritesList: data,
    isLoading,
    isError: error,
    isValidating,
  };
};

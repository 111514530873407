export const formatResults = (result) => {
  const { type, name, district, city, state, country } =
    result.properties.geocoding;
  if (type === 'country') {
    return name;
  } else if (type === 'state') {
    return `${name}`;
  } else if (type === 'city') {
    return `${name} - ${state}`;
  } else if (type === 'district') {
    return `${name}, ${city} ${state ? '- ' + state : ''}`;
  } else {
    return `${name}, ${district ? district + ' - ' : ''}${city} - ${state}`;
  }
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ServicesPreviewCard from "../components/Cards/ServicesPreviewCard";
import HomeAutocomplete from "../components/Inputs/HomeAutocomplete";
import pedreiroIcon from "../animations/services/svg/pedreiro.svg";
import eletricistaIcon from "../animations/services/svg/eletricista.svg";
import encanadorIcon from "../animations/services/svg/encanador.svg";
import pintorIcon from "../animations/services/svg/pintor.svg";
import diaristaIcon from "../animations/services/svg/diarista2.svg";
import cozinheiroIcon from "../animations/services/svg/cozinheiro.svg";
import marceneiroIcon from "../animations/services/svg/marceneiro.svg";
import jardineiroIcon from "../animations/services/svg/jardineiro.svg";
import acompanhanteIcon from "../animations/services/svg/acompanhante2.svg";
import mudancaIcon from "../animations/services/svg/mudanca.svg";
import fotografoIcon from "../animations/services/svg/fotografo.svg";

import RecentSearchesCard from "../components/Cards/RecentSearchesCard";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { styled } from "@mui/system";
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
} from "@mui/material";

const servicesIcons = [
  {
    title: "Mudança",
    icon: mudancaIcon,
  },
  {
    title: "Diarista",
    icon: diaristaIcon,
  },
  {
    title: "Pedreiro",
    icon: pedreiroIcon,
  },
  {
    title: "Eletricista",
    icon: eletricistaIcon,
  },
  {
    title: "Encanador",
    icon: encanadorIcon,
  },
  {
    title: "Pintor",
    icon: pintorIcon,
  },
  {
    title: "Cozinheiro",
    icon: cozinheiroIcon,
  },
  {
    title: "Marceneiro",
    icon: marceneiroIcon,
  },
  {
    title: "Jardineiro",
    icon: jardineiroIcon,
  },
  {
    title: "Acompanhante",
    icon: acompanhanteIcon,
  },

  {
    title: "Fotógrafo",
    icon: fotografoIcon,
  },
];

const StyledToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
  position: "relative",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  borderRadius: "16px",
  padding: "2px",
  width: "160px",
  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.05)",
  transition: "all 0.3s ease",
  "&:hover": {
    boxShadow: "0 8px 16px rgba(16, 185, 129, 0.12)",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    width: "calc(50% - 4px)",
    height: "85%",
    backgroundColor: "#10B981",
    borderRadius: "12px",
    transition:
      "transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.3s ease",
    transform: "translateX(var(--slide-position, 2px))",
    top: "7.5%",
    left: "2px",
    zIndex: 0,
    boxShadow: "0 4px 12px rgba(16, 185, 129, 0.25)",
  },
  "& .MuiToggleButton-root": {
    flex: 1,
    padding: "8px 12px",
    position: "relative",
    zIndex: 1,
    fontSize: "0.875rem",
    fontWeight: "600",
    backgroundColor: "transparent !important",
    transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
    border: "none",
    minWidth: "78px",
    maxWidth: "78px",
    borderRadius: "12px",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
}));

// Update the CSS variable for the new width
if (typeof document !== "undefined") {
  document.documentElement.style.setProperty("--slide-position", "2px");
}

const HomePage = () => {
  const navigate = useNavigate();
  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false);
  const [isClearMapDialogOpen, setIsClearMapDialogOpen] = useState(false);
  const [isCreateAlertDialogOpen, setIsCreateAlertDialogOpen] = useState(false);
  const [map, setMap] = useState(null);
  const [selectedButton, setSelectedButton] = useState("alugar");
  const [currentHintIndex, setCurrentHintIndex] = useState(0);
  const searchHints = [
    "Apartamento 2 quartos no Cascatinha até R$ 2.000",
    "Casa com quintal no Granbery até R$ 3.500",
    "Kitnet mobiliada no São Mateus com 3 quartos",
    "Cobertura no Alto dos Passos com 3 vagas",
    "Casa no Bom Pastor com piscina até R$ 5.000",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentHintIndex((prev) => (prev + 1) % searchHints.length);
    }, 3200); // Adjusted to match typing animation duration plus a small pause
    return () => clearInterval(interval);
  }, []);

  const handleButtonClick = (value) => {
    const root = document.documentElement;
    root.style.setProperty(
      "--slide-position",
      value === "alugar" ? "2px" : "78px"
    );
    setSelectedButton(value);
  };

  const recentSearches =
    JSON.parse(localStorage.getItem("recentSearches")) || [];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white via-emerald-50/30 to-white">
      {/* Hero Section */}
      <section className="relative py-12 md:py-16">
        <div className="absolute inset-0 bg-gradient-to-b from-white to-emerald-50/10"></div>
        <div className="relative w-full max-w-3xl mx-auto px-4 flex flex-col justify-center items-center">
          <div className="flex flex-col mb-6 space-y-3 animate-fadeIn text-center">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold bg-gradient-to-r from-emerald-600 to-emerald-400 bg-clip-text text-transparent leading-tight">
              Encontre seu Lar Ideal
            </h1>
            <p className="text-gray-600 text-lg md:text-xl font-medium max-w-2xl mx-auto">
              Descubra o imóvel perfeito para você em nossa plataforma
              inteligente de busca
            </p>
          </div>

          <div
            className="w-full max-w-2xl mb-8 animate-slideDown"
            style={{ animationDelay: "100ms" }}
          >
            <div className="text-center space-y-2 mb-4">
              <span className="inline-flex items-center px-3 py-1.5 bg-emerald-100/80 text-emerald-800 rounded-full text-sm font-medium shadow-sm backdrop-blur-sm">
                <svg className="w-3 h-3 mr-2 fill-current" viewBox="0 0 16 16">
                  <circle cx="8" cy="8" r="8" className="animate-pulse" />
                </svg>
                Busca Inteligente com IA
              </span>

              <div className="relative h-6 overflow-hidden">
                <div className="typing-wrapper">
                  {/* <span className="text-emerald-600/70 text-sm font-medium">
                    Experimente:{" "}
                  </span> */}
                  <div className="typing-container">
                    <div
                      key={currentHintIndex}
                      className="typing-text text-emerald-600/70 text-xs font-medium italic"
                      data-text={`"${searchHints[currentHintIndex]}"`}
                    >
                      {`"${searchHints[currentHintIndex]}""`}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white/90 backdrop-blur-md rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 p-4">
              <HomeAutocomplete
                isFiltersDrawerOpen={isFiltersDrawerOpen}
                isCreateAlertDialogOpen={isCreateAlertDialogOpen}
                map={map}
              />

              <div className="mt-4 flex justify-center">
                <StyledToggleButtonGroup
                  value={selectedButton}
                  exclusive
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      handleButtonClick(newValue);
                    }
                  }}
                >
                  <MuiToggleButton
                    value="alugar"
                    sx={{
                      color:
                        selectedButton === "alugar"
                          ? "#ffffff !important"
                          : "#64748b !important",
                    }}
                  >
                    Alugar
                  </MuiToggleButton>
                  <MuiToggleButton
                    value="comprar"
                    sx={{
                      color:
                        selectedButton === "comprar"
                          ? "#ffffff !important"
                          : "#64748b !important",
                    }}
                  >
                    Comprar
                  </MuiToggleButton>
                </StyledToggleButtonGroup>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-16 bg-white/90 backdrop-blur-sm">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-12">
            <span className="text-emerald-600 font-semibold text-sm uppercase tracking-wider">
              Nossos Serviços
            </span>
            <h2 className="mt-3 text-3xl md:text-4xl font-bold text-gray-900">
              Serviços essenciais para seu imóvel
            </h2>
            <p className="mt-3 text-gray-600 text-lg max-w-2xl mx-auto">
              Encontre profissionais qualificados para cuidar do seu imóvel
            </p>
          </div>

          <div className="relative">
            <div className="hidden md:grid md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-8 gap-6 place-items-center">
              {servicesIcons.map((service, index) => (
                <div
                  key={index}
                  className="w-full flex justify-center transform transition-all duration-300 hover:-translate-y-2 hover:scale-105"
                >
                  <ServicesPreviewCard
                    title={service.title}
                    icon={service.icon}
                  />
                </div>
              ))}
            </div>
            <div className="flex md:hidden gap-4 overflow-x-auto pb-6 scrollbar-hide snap-x snap-mandatory">
              {servicesIcons.map((service, index) => (
                <div
                  key={index}
                  className="flex-none snap-center transform transition-all duration-300 hover:-translate-y-2"
                >
                  <ServicesPreviewCard
                    title={service.title}
                    icon={service.icon}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Recent Searches Section */}
      <section className="py-12 overflow-hidden bg-gradient-to-b from-white to-emerald-50/20">
        <div className="max-w-7xl mx-auto px-4">
          {recentSearches.length > 0 && (
            <div className="animate-slideUp">
              <div className="flex items-center justify-between mb-6">
                <div className="space-y-1">
                  <h2 className="text-2xl md:text-3xl font-bold text-gray-900">
                    Buscas recentes
                  </h2>
                  <p className="text-gray-600">Continue de onde parou</p>
                </div>
                <button className="text-emerald-600 hover:text-emerald-700 font-medium transition-colors duration-200">
                  Ver todas
                </button>
              </div>
              <div className="relative">
                <div className="flex overflow-x-auto gap-4 pb-4 snap-x snap-mandatory scroll-smooth hide-scrollbar">
                  {recentSearches.map((item, idx) => (
                    <div
                      key={idx}
                      className="flex-none w-[280px] sm:w-[320px] snap-start"
                    >
                      <div
                        className="bg-white rounded-xl border border-gray-100 shadow-sm hover:shadow-xl duration-300 hover:-translate-y-1 overflow-hidden cursor-pointer group"
                        style={{
                          animationDelay: `${idx * 50}ms`,
                          animationFillMode: "backwards",
                        }}
                      >
                        <RecentSearchesCard item={item} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default HomePage;

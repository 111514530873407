import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/index';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Helmet } from 'react-helmet';
import logo from './media/icons/rentia-logo-512.svg';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <div>
      <Helmet>
        <title>{`Rentia`}</title>
        <meta property='og:title' content={`Rentia`} />
        <meta
          property='og:description'
          content={`O melhor portal de imóveis do Brasil`}
        />
        <meta property='og:image' content={logo} />
        <meta property='og:url' content={window.location.href} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={`Rentia`} />
        <meta
          name='twitter:description'
          content={`O melhor portal de imóveis do Brasil`}
        />
        <meta name='twitter:image' content={logo} />
      </Helmet>
      <ToastContainer
        position='bottom-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={true}
        transition={Bounce}
        draggable={true}
        pauseOnHover
      />
      <App />
    </div>
  </Provider>
  // </React.StrictMode>
);

serviceWorkerRegistration.register();

import {
  AdminPanelSettings,
  Bathtub,
  BedOutlined,
  CameraAltOutlined,
  Chair,
  CreditScore,
  DirectionsCarFilledOutlined,
  Elevator,
  Favorite,
  Pets,
  Share,
  ShowerOutlined,
  SpaceDashboardOutlined,
  Stairs,
  WhatsApp,
} from '@mui/icons-material';
import {
  Button,
  Divider,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import housePlaceholder from '../../media/housePlaceholder.webp';
import {
  hasVisitedProperty,
  markPropertyAsVisited,
} from '../../utils/visitedProperties';
import { propertyVisited } from '../../services/propertyService';
import conciergeImg from '../../media/icons/concierge.png';
import { MapContainer, TileLayer } from 'react-leaflet';
import WrapperPropertyComponent from './WrapperPropertyComponent';
import { mapConfig } from '../../config/config';
import { toast } from 'react-toastify';
import {
  deleteFavorite,
  postFavorites,
  useGetFavorites,
} from '../../services/favoriteService';
import { mutate } from 'swr';

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 3,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 0,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 2,
    partialVisibilityGutter: 30,
  },
};

const PropertyDetails = ({ property }) => {
  const [isFavorite, setIsFavorite] = useState(false);

  const { favoritesList, isLoading, isError, isValidating } = useGetFavorites({
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false,
  });

  const copyToClipboard = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success('Link do imóvel copiado', {
          autoClose: 1500,
          position: 'bottom-center',
        });
      })
      .catch((error) => {
        console.error('Error copying URL to clipboard:', error);
      });
  };

  const handleWhatsapp = () => {
    const url = `https://wa.me/55${
      property.ownerPhone || '32991071618'
    }?text=Olá! Estou interessado no imóvel ${property._id}`;
    window.open(url, '_blank');
  };

  const handleFavorite = async () => {
    if (isFavorite) {
      await deleteFavorite(property._id)
        .then((data) => {
          toast.success('Imóvel removido dos favoritos', {
            autoClose: 1500,
            position: 'bottom-center',
          });
        })
        .catch((error) => {
          console.error('Error removing property from favorites:', error);
          toast.error('Erro ao remover imóvel dos favoritos', {
            autoClose: 1500,
            position: 'bottom-center',
          });
        });
    } else {
      await postFavorites(property._id)
        .then((data) => {
          toast.success('Imóvel adicionado aos favoritos', {
            autoClose: 1500,
            position: 'bottom-center',
          });
        })
        .catch((error) => {
          console.error('Error adding property to favorites:', error);
          toast.error('Erro ao adicionar imóvel aos favoritos', {
            autoClose: 1500,
            position: 'bottom-center',
          });
        });
    }
    mutate('favorites');
  };

  useEffect(() => {
    if (!isLoading && !isError && !isValidating) {
      console.log('Favorites:', favoritesList);
      if (
        favoritesList &&
        favoritesList.find((fav) => fav.propertyId === property._id)
      ) {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  }, [favoritesList, isLoading, isError, isValidating]);

  useEffect(() => {
    if (!property) {
      return;
    }

    if (!hasVisitedProperty(property._id)) {
      propertyVisited(property._id)
        .then((data) => {
          // console.log('Data:', data);
          if (data) {
            markPropertyAsVisited(property._id);
            console.log('Property visited:', property._id);
          }
        })
        .catch((error) => {
          console.error('Error incrementing visit counter:', error);
        });
    }
  }, [property]);

  return (
    <div className=''>
      {property && (
        <div className='flex flex-col gap-1'>
          <SpeedDial
            ariaLabel='Menu de ações'
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
          >
            <SpeedDialAction
              icon={<Share />}
              tooltipTitle={'Compartilhar'}
              onClick={copyToClipboard}
            />
            <SpeedDialAction
              icon={
                <Favorite
                  sx={{
                    color: `${isFavorite ? 'red' : ''}`,
                  }}
                />
              }
              tooltipTitle={`${
                isFavorite ? 'Remover dos favoritos' : 'Adicionar aos favoritos'
              }`}
              onClick={handleFavorite}
            />
            <SpeedDialAction
              icon={<WhatsApp />}
              tooltipTitle={'Whatsapp'}
              onClick={handleWhatsapp}
            />
          </SpeedDial>
          <div className='flex justify-center items-center py-4'>
            <h1 className='text-xl font-bold'>Detalhes do Imóvel</h1>
          </div>
          <div className='flex flex-col  pl-4'>
            <div className='flex gap-2'>
              <h1 className='text-sm md:text-base font-bold'>Id:</h1>
              <h1 className='text-sm md:text-base '>{property._id}</h1>
            </div>
            <div className='flex gap-2'>
              <h1 className='text-sm md:text-base font-bold'>Adicionado em:</h1>
              <h1 className='text-sm md:text-base'>
                {new Date(property.dateCreate).toLocaleDateString()}
              </h1>
            </div>
          </div>
          <div className='relative  rounded-sm mx-2 shadow-lg'>
            <Carousel
              responsive={responsive}
              infinite={true}
              slidesToSlide={1}
              className='w-full'
              // containerClass='container'
              swipeable
              draggable
              autoPlay={true}
              autoPlaySpeed={10000}
              partialVisible={true}
              // centerMode={true}
              keyBoardControl={true}
              arrows={true}
            >
              {property &&
                [...property.images]
                  .sort((a, b) => {
                    if (a.isCover) return -1;
                    if (b.isCover) return 1;
                  })
                  .map((image) => (
                    <div className='flex mx-2' key={image.name}>
                      <img
                        src={image.url || housePlaceholder}
                        alt='property'
                        className='w-fit h-80 object-cover'
                      />
                    </div>
                  ))}
            </Carousel>
            <div className='absolute top-4 left-10'>
              <Button
                startIcon={<CameraAltOutlined />}
                variant='contained'
                color='success'
              >
                {`${property.images.length} ${
                  property.images.length > 1 ? 'fotos' : 'foto'
                }`}
              </Button>
            </div>
          </div>
          <Divider />
          <div className='flex flex-col w-full p-4'>
            <h1 className='text-base md:text-lg lg:text-xl font-bold'>
              Valor total
            </h1>
            <h1 className='text-lg md:text-xl lg:text-2xl font-bold text-emerald-700'>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(property.totalPrice)}
            </h1>
            <div className='flex flex-col mt-2'>
              <div className='flex gap-2'>
                <h1 className='text-sm'>Aluguel:</h1>
                <h1 className='text-sm'>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(property.price)}
                </h1>
              </div>
              <div className='flex gap-2'>
                <h1 className='text-sm'>Condomínio:</h1>
                <h1 className='text-sm'>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(property.monthlyTax)}
                </h1>
              </div>
              <div className='flex gap-2'>
                <h1 className='text-sm'>IPTU:</h1>
                <h1 className='text-sm'>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(property.iptu / 12)}
                </h1>
              </div>
            </div>
          </div>
          <div className='flex flex-col p-4'>
            <h1 className='text-base md:text-lg font-bold'>Tipo de imóvel</h1>
            <h1 className='text-base md:text-lg'>{property.type}</h1>
          </div>
          <div className='flex flex-col gap-6 p-4'>
            <h1 className='text-base md:text-lg font-bold'>
              Características do imóvel
            </h1>

            <div className='grid md:w-fit grid-cols-2 xs:grid-cols-3 md:grid-cols-4 gap-8 border border-gray-200 rounded-lg p-4 shadow-lg bg-slate-200'>
              <div className='flex flex-col justify-center items-center'>
                <h1 className='font-medium'>Área</h1>
                <div className='flex items-center gap-1'>
                  <SpaceDashboardOutlined className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${property.area}m²`}</p>
                </div>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <h1 className='font-medium'>Quartos</h1>
                <div className='flex items-center gap-1'>
                  <BedOutlined className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${property.bedrooms}`}</p>
                </div>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <h1 className='font-medium'>Banheiros</h1>
                <div className='flex items-center gap-1'>
                  <ShowerOutlined className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${property.bathrooms}`}</p>
                </div>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <h1 className='font-medium'>Suites</h1>
                <div className='flex items-center gap-1'>
                  <Bathtub className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${property.suites}`}</p>
                </div>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <h1 className='font-medium'>Vagas</h1>
                <div className='flex items-center gap-1'>
                  <DirectionsCarFilledOutlined className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${property.parkingSlots}`}</p>
                </div>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <h1 className='font-medium'>Mobiliado</h1>
                <div className='flex items-center gap-1'>
                  <Chair className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${
                    property.furnished ? 'Sim' : 'Não'
                  }`}</p>
                </div>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <h1 className='font-medium'>Fiador</h1>
                <div className='flex items-center gap-1'>
                  <AdminPanelSettings className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${
                    property.guarantor ? 'Sim' : 'Não'
                  }`}</p>
                </div>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <h1 className='font-medium'>Caução</h1>
                <div className='flex items-center gap-1'>
                  <CreditScore className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${
                    property.deposit ? 'Sim' : 'Não'
                  }`}</p>
                </div>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <h1 className='font-medium'>Portaria</h1>
                <div className='flex items-center gap-1'>
                  <img src={conciergeImg} className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${
                    property.deposit ? 'Sim' : 'Não'
                  }`}</p>
                </div>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <h1 className='font-medium'>Elevador</h1>
                <div className='flex items-center gap-1'>
                  <Elevator className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${
                    property.elevator ? 'Sim' : 'Não'
                  }`}</p>
                </div>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <h1 className='font-medium'>Andar</h1>
                <div className='flex items-center gap-1'>
                  <Stairs className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${property.floorNumber}`}</p>
                </div>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <h1 className='font-medium'>Aceita pet?</h1>
                <div className='flex items-center gap-1'>
                  <Pets className='w-6 h-6 text-gray-500' />
                  <p className='text-sm text-gray-500 font-medium'>{`${
                    property.petFriendly ? 'Sim' : 'Não'
                  }`}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col w-full gap-4 p-4'>
            <div className='flex flex-col w-full gap-2'>
              <h1 className='text-lg font-bold'>Descrição completa</h1>
              <p>{property.description}</p>
            </div>
          </div>
          <div className='flex flex-col w-full gap-4 p-4'>
            <div className='flex flex-col w-full gap-2'>
              <h1 className='text-lg font-bold'>Localização</h1>
              <p>{`${property.address}, ${property.neighborhood}, ${property.city}`}</p>
            </div>
            <div className='flex flex-col w-full gap-2 border border-gray-200 rounded-lg p-2 shadow-lg bg-slate-200'>
              {property && (
                <MapContainer
                  center={[
                    property.location.coordinates[1],
                    property.location.coordinates[0],
                  ]}
                  dragging={false}
                  touchZoom={false}
                  doubleClickZoom={false}
                  scrollWheelZoom={false}
                  boxZoom={false}
                  keyboard={false}
                  tapHold={false}
                  tap={false}
                  zoomControl={false}
                  zoom={16}
                  style={{
                    // width: '350px',
                    height: '410px',
                  }}
                >
                  {/* <WrapperRecentSearchesComponent item={item} /> */}
                  <WrapperPropertyComponent item={property} />
                  <TileLayer
                    attribution='Tiles &copy; <a href="https://www.esri.com/">Esri</a>'
                    url={mapConfig.map_tile_layer}
                  />
                </MapContainer>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyDetails;

import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { drawingMapConfig } from "../../config/config";
import { Place, AccessTime } from "@mui/icons-material";
import WrapperRecentSearchesComponent from "../RecentSearches/WrapperRecentSearchesComponent";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { objectValuesActions } from "../../store/objectValues";

const RecentSearchesCard = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelectedRecentSearch = () => {
    const searchParams = new URLSearchParams();
    Object.keys(item.filters).forEach((key) => {
      if (item.filters[key] !== "any" && item.filters[key] !== "") {
        searchParams.append(key, item.filters[key]);
      }
    });
    dispatch(
      objectValuesActions.setSelectedSearchResult(item.selectedSearchResult)
    );
    navigate(`/buscar?${searchParams.toString()}`);
  };

  const formatAddress = () => {
    const { properties } = item.selectedSearchResult;
    if (properties?.address) return properties.address;
    if (properties?.neighborhood)
      return `${properties.neighborhood}, ${properties.city}`;
    return properties?.city || "Localização";
  };

  return (
    <div
      onClick={handleSelectedRecentSearch}
      className="flex flex-col h-full group"
    >
      {/* Map Preview */}
      <div className="relative h-40 w-full overflow-hidden rounded-t-xl">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/20 z-10"></div>
        <MapContainer
          center={drawingMapConfig.map_default_center}
          zoom={drawingMapConfig.map_default_zoom}
          dragging={false}
          touchZoom={false}
          doubleClickZoom={false}
          scrollWheelZoom={false}
          boxZoom={false}
          keyboard={false}
          tapHold={false}
          tap={false}
          zoomControl={false}
          style={{ height: "100%", width: "100%" }}
          className="rounded-t-xl group-hover:scale-110 transition-transform duration-500"
        >
          <WrapperRecentSearchesComponent item={item} />
          <TileLayer
            attribution='Tiles &copy; <a href="https://www.esri.com/">Esri</a>'
            url={drawingMapConfig.map_tile_layer}
          />
        </MapContainer>
      </div>

      {/* Card Content */}
      <div className="flex flex-col gap-3 p-4">
        {/* Address */}
        <div className="flex items-start gap-2">
          <Place
            className="text-emerald-600 flex-shrink-0 mt-1 transition-colors group-hover:text-emerald-500"
            sx={{ fontSize: 20 }}
          />
          <p className="text-gray-900 font-medium line-clamp-2 group-hover:text-emerald-600 transition-colors">
            {formatAddress()}
          </p>
        </div>

        {/* Date and Filters Summary */}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1.5">
            <AccessTime className="text-gray-400" sx={{ fontSize: 16 }} />
            <p className="text-gray-500 text-sm">{item.dateCreate}</p>
          </div>
          <div className="flex items-center gap-2">
            <span
              className={`px-2 py-0.5 text-xs font-medium rounded-full transition-colors ${
                item.filters.action === "rent"
                  ? "bg-emerald-100 text-emerald-700 group-hover:bg-emerald-200"
                  : "bg-blue-100 text-blue-700 group-hover:bg-blue-200"
              }`}
            >
              {item.filters.action === "rent" ? "Alugar" : "Comprar"}
            </span>
            {item.filters.mainType && (
              <span className="px-2 py-0.5 text-xs font-medium rounded-full bg-gray-100 text-gray-700 group-hover:bg-gray-200 transition-colors">
                {item.filters.mainType === "residential"
                  ? "Residencial"
                  : "Comercial"}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentSearchesCard;

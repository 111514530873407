import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { Cross2Icon } from "@radix-ui/react-icons";
import { useClickAway, useDebounce, useMediaQuery } from "@uidotdev/usehooks";
import { useEffect, useState, useRef } from "react";
import { searchAddress, searchGemini } from "../../services/mapService";
import { mutate } from "swr";
import { formatResults } from "../../helpers/formattingFunctions";
import { useDispatch, useSelector } from "react-redux";
import { objectValuesActions } from "../../store/objectValues";
import { propertyValuesActions } from "../../store/propertyValues";
import {
  useGetPropertiesByGeometry,
  useGetPropertiesByMapBounds,
} from "../../services/propertyService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MapsHomeWork } from "@mui/icons-material";
import Lottie from "react-lottie";
import deleteAnimation from "../../animations/delete.json";
import L from "leaflet";
import loadingLottieWhite from "../../animations/loading-white.json";
import loadingLottieGreen from "../../animations/loading-green.json";
import VoiceSearch from "../Voice-Search/VoiceSearch";

const HomeAutocomplete = ({
  isFiltersDrawerOpen,
  isCreateAlertDialogOpen,
  map,
}) => {
  const [searchText, setSearchText] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [searchResults, setSearchResults] = useState();
  const [deleteAnimationPaused, setDeleteAnimationPaused] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 500);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const { propertiesCounter, selectedProperty } = useSelector(
    (state) => state.propertyValues
  );
  const { menuDrawerOpen, loginDrawerOpen } = useSelector(
    (state) => state.drawerValues
  );
  const { mapZoom, mapBounds } = useSelector((state) => state.mapValues);
  const { selectedSearchResult } = useSelector((state) => state.objectValues);

  const filters = {};
  Array.from(searchParams.entries()).forEach((item) => {
    filters[item[0]] = item[1];
  });

  const isExtraSmallDevice = useMediaQuery(
    "only screen and (max-width : 450px)"
  );

  const clearLayers = () => {
    map?.eachLayer((layer) => {
      if (
        layer instanceof L.Circle ||
        layer instanceof L.Polyline ||
        layer instanceof L.Polygon ||
        layer instanceof L.Marker ||
        layer instanceof L.MarkerClusterGroup
      ) {
        map.removeLayer(layer);
      }
    });
  };

  const {
    propertiesByGeometry,
    isLoadingPropertiesByGeometry,
    isErrorPropertiesByGeometry,
    isValidatingPropertiesByGeometry,
  } = useGetPropertiesByGeometry(
    selectedSearchResult,
    filters,
    filters["action"] || "rent",
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const {
    propertiesByMapBounds,
    isLoadingPropertiesByMapBounds,
    isErrorPropertiesByMapBounds,
    isValidatingPropertiesByMapBounds,
  } = useGetPropertiesByMapBounds(
    mapZoom,
    mapBounds,
    selectedSearchResult,
    filters,
    filters["action"] || "rent",
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 2000,
    }
  );

  useEffect(() => {
    if (debouncedSearchText && debouncedSearchText.length > 2) {
      setIsLoading(true);
      searchGemini(debouncedSearchText)
        .then((data) => {
          if (data.type === "noResults") {
          }
          setSearchResults(data);
          setShowResults(true);
          console.log("Search Results:", data);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [debouncedSearchText]);

  const handleInputChange = (e) => {
    if (e.target.value === "") {
      dispatch(objectValuesActions.setSelectedSearchResult(null));
      dispatch(propertyValuesActions.setPropertiesCounter(0));
      setSearchResults(null);
      mutate("searchAddress", null);
    }
    setSearchText(e.target.value);
  };

  const handleSelectedResult = (result) => {
    // First clear existing searchParams and filters
    setSearchParams(new URLSearchParams());
    dispatch(objectValuesActions.setSelectedSearchResult(result));
    setShowResults(false);

    // Delay setting new filters to ensure old ones are cleared
    setTimeout(() => {
      if (result.properties?.filters) {
        const filtersToSet = Object.entries(result.properties.filters).filter(
          ([key, value]) =>
            key !== "city" &&
            key !== "neighborhood" &&
            key !== "address" &&
            value !== undefined &&
            value !== null &&
            value !== ""
        );

        setSearchParams((params) => {
          // Set action param first if not exists
          if (!params.get("action")) {
            params.set("action", "rent");
          }

          // Then set all other filters
          filtersToSet.forEach(([key, value]) => {
            params.set(key, value);
          });

          // Set default searchRadius for non-polygon searches
          if (
            result.geometry?.type !== "Polygon" &&
            !params.get("searchRadius")
          ) {
            params.set("searchRadius", "0.5");
          }

          return params;
        });
      }

      navigate("/buscar");
    }, 0);
  };

  const handleClearMap = () => {
    dispatch(objectValuesActions.resetObjectValues());
    dispatch(objectValuesActions.setSelectedSearchResult(null));
    dispatch(propertyValuesActions.setPropertiesCounter(0));
    clearLayers();
    mutate("properties");
    setSearchText("");
    setSearchResults(null);
    mutate("searchAddress", null);
    dispatch(objectValuesActions.setSelectedSearchResult(null));
    dispatch(propertyValuesActions.setPropertiesCounter(0));
  };

  const handleVoiceResult = (result) => {
    setSearchText(result);
  };

  const handleDirectClear = () => {
    dispatch(objectValuesActions.resetObjectValues());
    dispatch(objectValuesActions.setSelectedSearchResult(null));
    dispatch(propertyValuesActions.setPropertiesCounter(0));
    clearLayers();
    mutate("properties");
    setSearchText("");
    setSearchResults(null);
    mutate("searchAddress", null);
  };

  const handleKeyDown = (e) => {
    if (!searchResults?.length) return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setSelectedIndex((prev) =>
          prev < searchResults.length - 1 ? prev + 1 : prev
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedIndex((prev) => (prev > 0 ? prev - 1 : 0));
        break;
      case "Enter":
        if (selectedIndex >= 0 && searchResults[selectedIndex]) {
          handleSelectedResult(searchResults[selectedIndex]);
        }
        break;
      case "Escape":
        setShowResults(false);
        inputRef.current?.blur();
        break;
    }
  };

  const resultsRef = useClickAway(() => {
    setShowResults(false);
  });

  return (
    <div className="flex flex-col w-full relative min-h-[48px]">
      <div className="group border border-gray-200 rounded-2xl focus-within:ring-2 focus-within:ring-emerald-500 focus-within:border-emerald-500 w-full flex bg-white items-center justify-between shadow-sm hover:shadow-md transition-all duration-200">
        <div className="pl-1.5 flex-none">
          <VoiceSearch
            onResult={handleVoiceResult}
            setSearchText={setSearchText}
          />
        </div>
        <input
          ref={inputRef}
          className="flex-1 min-w-0 py-3.5 px-0.5 text-ellipsis focus:outline-none rounded-2xl text-gray-700 placeholder:text-gray-400"
          type="text"
          placeholder="Digite ou fale sua busca..."
          value={searchText}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={() => searchResults?.length > 0 && setShowResults(true)}
        />
        {isLoading ? (
          <div className="pr-1.5">
            <div className="w-6 h-6 flex items-center justify-center">
              <CircularProgress
                size={18}
                thickness={4}
                className="text-emerald-500"
              />
            </div>
          </div>
        ) : (
          searchText && (
            <div className="pr-1.5">
              <Tooltip title="Limpar Busca" arrow>
                <button
                  className="p-1 rounded-full hover:bg-gray-100 transition-colors"
                  onClick={handleDirectClear}
                  onMouseEnter={() => setDeleteAnimationPaused(false)}
                  onMouseLeave={() => setDeleteAnimationPaused(true)}
                >
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: false,
                      animationData: deleteAnimation,
                    }}
                    height={20}
                    width={20}
                    isStopped={deleteAnimationPaused}
                  />
                </button>
              </Tooltip>
            </div>
          )
        )}
      </div>

      <div
        className={`w-full bg-white border border-gray-100 rounded-xl shadow-lg max-h-[320px] overflow-y-auto absolute top-[calc(100%+8px)] z-[9999] transition-all duration-200 ${
          showResults &&
          searchText &&
          searchResults &&
          searchResults.type !== "noResults"
            ? "opacity-100 translate-y-0"
            : "opacity-0 -translate-y-2 pointer-events-none"
        }`}
        ref={resultsRef}
      >
        {Array.isArray(searchResults) &&
          searchResults.map((result, index) => (
            <div
              key={result.properties.geocoding.place_id}
              className={`p-3 hover:bg-gray-50 cursor-pointer transition-colors duration-150 ${
                index === selectedIndex
                  ? "bg-emerald-50 border-l-4 border-emerald-500"
                  : ""
              }`}
              onClick={() => handleSelectedResult(result)}
            >
              <h1 className="text-sm text-gray-800 font-medium">
                {formatResults(result)}
              </h1>
              <p className="text-xs text-gray-500 mt-1.5">
                {result.properties.geocoding.type}
              </p>
            </div>
          ))}
        {searchResults?.length === 0 && (
          <div className="p-4 text-center text-gray-500">
            Nenhum resultado encontrado
          </div>
        )}
        {searchResults?.type === "noResults" && (
          <div className="p-4 text-center text-gray-500">
            {searchResults.reason}
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeAutocomplete;

import React from 'react';
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import polygon from '../../media/polygon-removebg-preview.png';
import { Link, useNavigate } from 'react-router-dom';
import {
  FavoriteOutlined,
  HouseOutlined,
  Logout,
  NotificationsActive,
  SettingsOutlined,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { drawerValuesActions } from '../../store/drawerValues';
import { auth } from '../../firebase-config';
import { deepOrange } from '@mui/material/colors';
import { toast } from 'react-toastify';

const MyAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userName } = useSelector((state) => state.loginValues);
  const handleLogout = () => {
    auth.signOut().then(() => {
      toast.success('Desconectado com sucesso', {
        autoClose: 1000,
        position: 'bottom-center',
      });
      // navigate('/');
    });
  };
  const handleNavigation = (path) => {
    dispatch(drawerValuesActions.setLoginDrawer(false));
    navigate(path);
  };
  return (
    <div className='flex flex-col h-full '>
      <div className='flex px-6 py-4 gap-4 items-center'>
        <div className=''>
          <Avatar sx={{ bgcolor: deepOrange[500] }}>
            {userName?.charAt(0) || 'U'}
          </Avatar>
        </div>
        <div>
          <h1 className='text-xl font-bold'>
            {userName ? userName.split(' ')[0] : 'Usuário'}
          </h1>
          <Link
            className='text-emerald-800 cursor-pointer text-sm font-semibold hover:underline'
            to='/perfil'
          >
            Ver meu perfil
          </Link>
        </div>
      </div>
      <div className='grow'>
        <div>
          <Divider my='3' size='4' />
        </div>
        <List>
          <ListItem onClick={() => handleNavigation('/minhas-propriedades')}>
            <ListItemButton>
              <ListItemIcon>
                <HouseOutlined />
              </ListItemIcon>
              <ListItemText primary='Meus imóveis' />
            </ListItemButton>
          </ListItem>
          <ListItem onClick={() => handleNavigation('/favoritos')}>
            <ListItemButton>
              <ListItemIcon>
                <FavoriteOutlined />
              </ListItemIcon>
              <ListItemText primary='Imóveis salvos' />
            </ListItemButton>
          </ListItem>
          <ListItem onClick={() => handleNavigation('/areas-desenhadas')}>
            <ListItemButton>
              <ListItemIcon>
                <img className='ml-0.5' width={20} src={polygon} />
              </ListItemIcon>
              <ListItemText primary='Áreas desenhadas' />
            </ListItemButton>
          </ListItem>
          <ListItem onClick={() => handleNavigation('/meus-alertas')}>
            <ListItemButton>
              <ListItemIcon>
                <NotificationsActive fontSize='medium' />
              </ListItemIcon>
              <ListItemText primary='Alertas' />
            </ListItemButton>
          </ListItem>
        </List>
      </div>
      <div className=''>
        <div>
          <Divider my='3' size='4' />
        </div>
        <List>
          <ListItem onClick={() => handleNavigation('/configuracoes')}>
            <ListItemButton>
              <ListItemIcon>
                <SettingsOutlined />
              </ListItemIcon>
              <p className=''>Configurações</p>
            </ListItemButton>
          </ListItem>
          <ListItem onClick={handleLogout}>
            <ListItemButton>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>

              <p className=''>Sair</p>
            </ListItemButton>
          </ListItem>
        </List>
      </div>
    </div>
  );
};

export default MyAccount;

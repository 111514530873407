import React, { createContext, useState, useContext } from 'react';

const VoiceSearchContext = createContext();

export const VoiceSearchProvider = ({ children }) => {
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [finalTranscript, setFinalTranscript] = useState('');
  const [micAnimationPaused, setMicAnimationPaused] = useState(true);
  const [response, setResponse] = useState(null);

  const value = {
    isListening,
    setIsListening,
    transcript,
    setTranscript,
    finalTranscript,
    setFinalTranscript,
    micAnimationPaused,
    setMicAnimationPaused,
    response,
    setResponse
  };

  return (
    <VoiceSearchContext.Provider value={value}>
      {children}
    </VoiceSearchContext.Provider>
  );
};

export const useVoiceSearch = () => {
  const context = useContext(VoiceSearchContext);
  if (!context) {
    throw new Error('useVoiceSearch must be used within a VoiceSearchProvider');
  }
  return context;
};

export default VoiceSearchContext;
import { useState } from 'react';
import LoginDrawer from '../Drawers/LoginDrawer';
import MenuDrawer from '../Drawers/MenuDrawer';
import { useNavigate } from 'react-router-dom';

import logo from '../../media/icons/rentia-logo-512.svg';
import { useDispatch, useSelector } from 'react-redux';
import { drawerValuesActions } from '../../store/drawerValues';
import Lottie from 'react-lottie';
import avatarAnimation from '../../animations/Individual.json';
import menuAnimation from '../../animations/Menu.json';

const NavBar = () => {
  const [menuAnimationPaused, setMenuAnimationPaused] = useState(true);
  const [avatarAnimationPaused, setAvatarAnimationPaused] = useState(true);

  const { userId } = useSelector((state) => state.loginValues);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <nav className='flex items-center justify-between p-8 bg-white relative'>
      <MenuDrawer />
      <LoginDrawer />
      <div className='flex items-center absolute left-4'>
        <button
          onClick={() => dispatch(drawerValuesActions.setMenuDrawer(true))}
          className='text-sm border-transparent duration-100 border rounded-lg hover:bg-emerald-600 hover:bg-opacity-60'
          onMouseEnter={() => setMenuAnimationPaused(false)}
          onMouseLeave={() => setMenuAnimationPaused(true)}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: false,
              animationData: menuAnimation,
            }}
            height={35}
            width={35}
            isStopped={menuAnimationPaused}
          />
        </button>
      </div>
      <div
        className='flex items-center gap-2 hover:cursor-pointer absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2'
        onClick={() => navigate('/')}
      >
        <img src={logo} alt='Rentia Logo' className='w-8 h-8' />
        <h1 className='font-semibold text-xl '>Rentia</h1>
      </div>
      <div className='absolute right-4'>
        <div className='hidden lg:flex '>
          <button
            onClick={() => dispatch(drawerValuesActions.setLoginDrawer(true))}
            className='font-semibold text-lg flex items-center gap-2 p-1 border-transparent text-black duration-100  rounded-lg hover:bg-emerald-600 hover:bg-opacity-60'
            onMouseEnter={() => setAvatarAnimationPaused(false)}
            onMouseLeave={() => setAvatarAnimationPaused(true)}
          >
            <Lottie
              options={{
                loop: true,
                autoplay: false,
                animationData: avatarAnimation,
              }}
              height={25}
              width={25}
              isStopped={avatarAnimationPaused}
            />
            {userId ? 'Minha conta' : 'Entrar'}
          </button>
        </div>
        <div className='lg:hidden flex items-center'>
          <button
            onClick={() => dispatch(drawerValuesActions.setLoginDrawer(true))}
            className='flex items-center gap-2 px-3 py-1.5 border-transparent text-sm text-black duration-100  rounded-lg hover:bg-emerald-400 '
          >
            <Lottie
              options={{
                loop: true,
                autoplay: false,
                animationData: avatarAnimation,
              }}
              height={25}
              width={25}
            />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;

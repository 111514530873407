import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPropertyById } from '../services/propertyService';
import { Backdrop, CircularProgress } from '@mui/material';
import PropertyDetails from '../components/Property/PropertyDetails';

const PropertyPage = () => {
  const { id } = useParams();

  const { property, isLoading, isError } = useGetPropertyById(id, {
    revalidateOnFocus: false,
    revalidateOnReconnect: true,
  });

  useEffect(() => {
    property && console.log('Propriedade: ', property);
  }, [property]);

  if (isLoading) {
    return (
      <Backdrop open={isLoading} style={{ zIndex: 99 }}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  if (isError) {
    return <div>Erro ao carregar o imóvel</div>;
  }

  if (property && property.message) {
    return (
      <div>
        <h1>Imóvel não encontrado. Recarregue a página.</h1>
        <button
          className='bg-emerald-700 bg-opacity-50'
          onClick={() => window.location.reload()}
        >
          Recarregar
        </button>
      </div>
    );
  }

  return (
    <div className=''>
      {property && (
        <div>
          <PropertyDetails property={property} />
        </div>
      )}
    </div>
  );
};

export default PropertyPage;

import React, { useState } from "react";
import {
  LayersControl,
  MapContainer,
  TileLayer,
  ZoomControl,
} from "react-leaflet";

import WrapperSearchResultsComponent from "../components/SearchResults/WrapperSearchResultsComponent";
import { mapConfig, satellite, streets, topo } from "../config/config";
import SearchResultsMapButtons from "../components/Buttons/SearchResultsMapButtons";
import { useSelector } from "react-redux";
import Autocomplete from "../components/Inputs/Autocomplete";
import { useMediaQuery } from "@uidotdev/usehooks";

const SearchResultsPage = () => {
  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false);
  const [isCreateAlertDialogOpen, setIsCreateAlertDialogOpen] = useState(false);
  const [isClearMapDialogOpen, setIsClearMapDialogOpen] = useState(false);
  const [map, setMap] = useState(null);

  const { selectedProperty } = useSelector((state) => state.propertyValues);
  const { loginDrawerOpen, menuDrawerOpen } = useSelector(
    (state) => state.drawerValues
  );

  const isExtraSmallDevice = useMediaQuery(
    "only screen and (max-width : 768px)"
  );

  return (
    <div className="h-full">
      <MapContainer
        center={mapConfig.map_default_center}
        zoom={mapConfig.map_default_zoom}
        // scrollWheelZoom={false}
        // key={isLargeDevice ? 'large' : 'small'}
        zoomControl={false}
        maxZoom={mapConfig.map_max_zoom}
        minZoom={mapConfig.map_min_zoom}
        trackResize={true}
        style={{ width: "100%", height: "100%" }}
        closePopupOnClick={false}
        ref={setMap}
        // maxBounds={mapConfig.maxBounds}
        attributionControl={false}
      >
        <WrapperSearchResultsComponent
          isFiltersDrawerOpen={isFiltersDrawerOpen}
          isCreateAlertDialogOpen={isCreateAlertDialogOpen}
          isClearMapDialogOpen={isClearMapDialogOpen}
          setIsFiltersDrawerOpen={setIsFiltersDrawerOpen}
          setIsCreateAlertDialogOpen={setIsCreateAlertDialogOpen}
          setIsClearMapDialogOpen={setIsClearMapDialogOpen}
        />

        <LayersControl
          position={isExtraSmallDevice ? "bottomleft" : "topright"}
        >
          <LayersControl.BaseLayer name="Mapa" checked>
            <TileLayer url={streets} />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Mapa Detalhado">
            <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satélite">
            <TileLayer url={satellite} />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Topográfico">
            <TileLayer url={topo} />
          </LayersControl.BaseLayer>
        </LayersControl>
        <ZoomControl position={isExtraSmallDevice ? "bottomleft" : "topleft"} />
      </MapContainer>
      <div
        className={`${
          selectedProperty ||
          isFiltersDrawerOpen ||
          isCreateAlertDialogOpen ||
          isClearMapDialogOpen ||
          loginDrawerOpen ||
          menuDrawerOpen
            ? "hidden"
            : ""
        } absolute z-[999] bottom-2 right-2 flex flex-col items-center justify-center`}
      >
        <SearchResultsMapButtons
          isFiltersDrawerOpen={isFiltersDrawerOpen}
          isCreateAlertDialogOpen={isCreateAlertDialogOpen}
          isClearMapDialogOpen={isClearMapDialogOpen}
          setIsFiltersDrawerOpen={setIsFiltersDrawerOpen}
          setIsCreateAlertDialogOpen={setIsCreateAlertDialogOpen}
          setIsClearMapDialogOpen={setIsClearMapDialogOpen}
        />
      </div>
      <div className="absolute transform -translate-x-1/2 left-1/2 top-[75px] z-[999] w-[94%] max-w-[480px]">
        <Autocomplete
          isFiltersDrawerOpen={isFiltersDrawerOpen}
          isCreateAlertDialogOpen={isCreateAlertDialogOpen}
          isClearMapDialogOpen={isClearMapDialogOpen}
          map={map}
        />
      </div>
    </div>
  );
};

export default SearchResultsPage;

import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const residentialTypes = {
  apartment: 'Apartamento',
  house: 'Casa',
  farm: 'Sítio/Fazenda/Chácara',
  terrain: 'Terreno/Lote',
};
const commercialTypes = {
  commercialHouse: 'Casa',
  office: 'Sala/Conjunto',
  store: 'Ponto Comercial/Loja/Box',
  depot: 'Depósito/Galpão/Armazém',
  clinic: 'Consultório/Clínica',
  building: 'Prédio/Edifício Inteiro',
  hotel: 'Hotel/Motel/Pousada',
};

const SecondStepForm = ({
  register,
  setValue,
  getValues,
  handleCepChange,
  handleNext,
  watch,
}) => {
  const { fullAddress } = useSelector((state) => state.addPropertyFormValues);
  const [searchParams, setSearchParams] = useSearchParams();

  const incrementValue = (field) => {
    setValue(field, Number(getValues(field)) + 1);
    setSearchParams((old) => {
      old.set(field, getValues(field));
      return old;
    });
  };

  const decrementValue = (field) => {
    if (field === 'floors' && getValues(field) === 1) return;
    if (field === 'floorNumber' && getValues(field) === 1) return;
    if (getValues(field) === 0) return;
    setValue(field, Number(getValues(field)) - 1);
    setSearchParams((old) => {
      old.set(field, getValues(field));
      return old;
    });
  };

  return (
    <div className='flex flex-col gap-6'>
      <div className='grid grid-cols-2 gap-4'>
        <div>
          <h3 className='mb-3 text-sm md:text-base font-medium text-gray-500'>
            Área útil:
          </h3>
          <div className='relative'>
            <input
              {...register('area')}
              type='number'
              inputMode='numeric'
              id='area'
              placeholder='Área útil em m²'
              className='w-full h-10 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500 focus:after:bg-emerald-300'
              required
            />
            <p className='absolute top-0 end-0 h-full p-2 text-sm font-medium text-black cursor-default bg-slate-100 rounded-e-lg border  outline-emerald-500 active:before:bg-emerald-300'>
              m²
            </p>
          </div>
        </div>
        <div>
          <h3 className='mb-3 text-sm md:text-base font-medium text-gray-500'>
            Área total:
          </h3>
          <div className='relative'>
            <input
              {...register('totalArea')}
              type='number'
              inputMode='numeric'
              id='totalArea'
              placeholder='Área total em m²'
              className='w-full h-10 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500 focus:after:bg-emerald-300'
              required
            />
            <p className='absolute top-0 end-0 h-full p-2 text-sm font-medium text-black cursor-default bg-slate-100 rounded-e-lg border  outline-emerald-500 active:before:bg-emerald-300'>
              m²
            </p>
          </div>
        </div>
      </div>
      <div className='flex justify-between bg-slate-50 transform border shadow-lg p-2'>
        <div>
          <h3 className='text-sm md:text-base font-medium text-gray-500'>
            Quartos:
          </h3>
          <h3 className='text-xs md:text-sm font-medium text-gray-500'>
            incluindo suites
          </h3>
        </div>
        <div className='flex gap-2 justify-evenly items-center'>
          <button
            type='button'
            className='w-8 h-8 text-white bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg flex justify-center items-center'
            onClick={() => decrementValue('bedrooms')}
          >
            -
          </button>
          <div className='flex w-8 items-center justify-center'>
            <p className='text-base sm:text-lg md:text-xl font-semibold'>
              {watch('bedrooms')}
            </p>
          </div>
          <button
            type='button'
            className='w-8 h-8 text-center text-white bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg flex justify-center items-center'
            onClick={() => incrementValue('bedrooms')}
          >
            +
          </button>
        </div>
      </div>
      <div className='flex justify-between bg-slate-50 transform border shadow-lg p-2'>
        <div>
          <h3 className='text-sm md:text-base font-medium text-gray-500'>
            Suites:
          </h3>
          {/* <h3 className='text-xs md:text-sm font-medium text-gray-500'>
            incluindo suites
          </h3> */}
        </div>
        <div className='flex gap-2 justify-evenly items-center'>
          <button
            type='button'
            className='w-8 h-8 text-white bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg flex justify-center items-center'
            onClick={() => decrementValue('suites')}
          >
            -
          </button>
          <div className='flex w-8 items-center justify-center'>
            <p className='text-base sm:text-lg md:text-xl font-semibold'>
              {watch('suites')}
            </p>
          </div>
          <button
            type='button'
            className='w-8 h-8 text-center text-white bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg flex justify-center items-center'
            onClick={() => incrementValue('suites')}
          >
            +
          </button>
        </div>
      </div>
      <div className='flex justify-between bg-slate-50 transform border shadow-lg p-2'>
        <div>
          <h3 className='text-sm md:text-base font-medium text-gray-500'>
            Banheiros:
          </h3>
          <h3 className='text-xs md:text-sm font-medium text-gray-500'>
            incluindo suites
          </h3>
        </div>
        <div className='flex gap-2 justify-evenly items-center'>
          <button
            type='button'
            className='w-8 h-8 text-white bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg flex justify-center items-center'
            onClick={() => decrementValue('bathrooms')}
          >
            -
          </button>
          <div className='flex w-8 items-center justify-center'>
            <p className='text-base sm:text-lg md:text-xl font-semibold'>
              {watch('bathrooms')}
            </p>
          </div>
          <button
            type='button'
            className='w-8 h-8 text-center text-white bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg flex justify-center items-center'
            onClick={() => incrementValue('bathrooms')}
          >
            +
          </button>
        </div>
      </div>
      <div className='flex justify-between bg-slate-50 transform border shadow-lg p-2'>
        <div>
          <h3 className='text-sm md:text-base font-medium text-gray-500'>
            Vagas de garagem:
          </h3>
          {/* <h3 className='text-xs md:text-sm font-medium text-gray-500'>
            incluindo suites
          </h3> */}
        </div>
        <div className='flex gap-2 justify-evenly items-center'>
          <button
            type='button'
            className='w-8 h-8 text-white bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg flex justify-center items-center'
            onClick={() => decrementValue('parkingSlots')}
          >
            -
          </button>
          <div className='flex w-8 items-center justify-center'>
            <p className='text-base sm:text-lg md:text-xl font-semibold'>
              {watch('parkingSlots')}
            </p>
          </div>
          <button
            type='button'
            className='w-8 h-8 text-center text-white bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg flex justify-center items-center'
            onClick={() => incrementValue('parkingSlots')}
          >
            +
          </button>
        </div>
      </div>
      <div className='flex justify-between bg-slate-50 transform border shadow-lg p-2'>
        <div>
          <h3 className='text-sm md:text-base font-medium text-gray-500'>
            Número de andares:
          </h3>
          {/* <h3 className='text-xs md:text-sm font-medium text-gray-500'>
            total de andares
          </h3> */}
        </div>
        <div className='flex gap-2 justify-evenly items-center'>
          <button
            type='button'
            className='w-8 h-8 text-white bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg flex justify-center items-center'
            onClick={() => decrementValue('floors')}
          >
            -
          </button>
          <div className='flex w-8 items-center justify-center'>
            <p className='text-base sm:text-lg md:text-xl font-semibold'>
              {watch('floors')}
            </p>
          </div>
          <button
            type='button'
            className='w-8 h-8 text-center text-white bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg flex justify-center items-center'
            onClick={() => incrementValue('floors')}
          >
            +
          </button>
        </div>
      </div>
      <div className='flex justify-between bg-slate-50 transform border shadow-lg p-2'>
        <div>
          <h3 className='text-sm md:text-base font-medium text-gray-500'>
            Andar do imóvel:
          </h3>
          {/* <h3 className='text-xs md:text-sm font-medium text-gray-500'>
            incluindo suites
          </h3> */}
        </div>
        <div className='flex gap-2 justify-evenly items-center'>
          <button
            type='button'
            className='w-8 h-8 text-white bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg flex justify-center items-center'
            onClick={() => decrementValue('floorNumber')}
          >
            -
          </button>
          <div className='flex w-8 items-center justify-center'>
            <p className='text-base sm:text-lg md:text-xl font-semibold'>
              {watch('floorNumber')}
            </p>
          </div>
          <button
            type='button'
            className='w-8 h-8 text-center text-white bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg flex justify-center items-center'
            onClick={() => incrementValue('floorNumber')}
          >
            +
          </button>
        </div>
      </div>
      <div className='flex justify-between bg-slate-50 transform border shadow-lg p-2'>
        <div>
          <h3 className='text-sm md:text-base font-medium text-gray-500'>
            Possui elevador?
          </h3>
        </div>
        <div className='flex gap-2 justify-center items-center'>
          <div>
            <input
              {...register('elevator')}
              type='radio'
              id='elevator'
              name='hasElevator'
              value='true'
              className='hidden peer'
              onChange={(e) => {
                setSearchParams((old) => {
                  old.set('elevator', e.target.value);
                  return old;
                });
                setValue('elevator', 'true');
              }}
              defaultChecked={searchParams.get('elevator') === 'true'}
            />
            <label
              htmlFor='elevator'
              className='flex w-full p-2 h-8 items-center text-emerald-700 bg-white border border-emerald-500 rounded cursor-pointer  peer-checked:border-black peer-checked:text-white peer-checked:bg-emerald-500  hover:bg-emerald-100 hover:bg-opacity-50'
            >
              <p className='w-full text-lg text-center font-semibold'>Sim</p>
            </label>
          </div>

          <div>
            <input
              {...register('elevator')}
              type='radio'
              id='notElevator'
              name='hasElevator'
              value='false'
              className='hidden peer'
              onChange={(e) => {
                setSearchParams((old) => {
                  old.set('elevator', e.target.value);
                  return old;
                });
                setValue('elevator', 'false');
              }}
              defaultChecked={searchParams.get('elevator') === 'false'}
            />
            <label
              htmlFor='notElevator'
              className='flex w-full p-2 h-8 items-center text-emerald-700 bg-white border border-emerald-500 rounded cursor-pointer  peer-checked:border-black peer-checked:text-white peer-checked:bg-emerald-500  hover:bg-emerald-100 hover:bg-opacity-50'
            >
              <p className='w-full text-lg text-center font-semibold'>Não</p>
            </label>
          </div>
        </div>
      </div>
      <div className='flex justify-between bg-slate-50 transform border shadow-lg p-2'>
        <div>
          <h3 className='text-sm md:text-base font-medium text-gray-500'>
            Necessita fiador?
          </h3>
          {/* <h3 className='text-xs md:text-sm font-medium text-gray-500'>
            incluindo suites
          </h3> */}
        </div>
        <div className='flex gap-2 justify-center items-center'>
          <div>
            <input
              {...register('guarantor')}
              type='radio'
              id='guarantor'
              name='hasGuarantor'
              value='true'
              className='hidden peer'
              onChange={(e) => {
                setSearchParams((old) => {
                  old.set('guarantor', e.target.value);
                  return old;
                });
                setValue('guarantor', 'true');
              }}
              defaultChecked={searchParams.get('guarantor') === 'true'}
            />
            <label
              htmlFor='guarantor'
              className='flex w-full p-2 h-8 items-center text-emerald-700 bg-white border border-emerald-500 rounded cursor-pointer  peer-checked:border-black peer-checked:text-white peer-checked:bg-emerald-500  hover:bg-emerald-100 hover:bg-opacity-50'
            >
              <p className='w-full text-lg text-center font-semibold'>Sim</p>
            </label>
          </div>

          <div>
            <input
              {...register('guarantor')}
              type='radio'
              id='notGuarantor'
              name='hasGuarantor'
              value='false'
              className='hidden peer'
              onChange={(e) => {
                setSearchParams((old) => {
                  old.set('guarantor', e.target.value);
                  return old;
                });
                setValue('guarantor', 'false');
              }}
              defaultChecked={searchParams.get('guarantor') === 'false'}
            />
            <label
              htmlFor='notGuarantor'
              className='flex w-full p-2 h-8 items-center text-emerald-700 bg-white border border-emerald-500 rounded cursor-pointer  peer-checked:border-black peer-checked:text-white peer-checked:bg-emerald-500  hover:bg-emerald-100 hover:bg-opacity-50'
            >
              <p className='w-full text-lg text-center font-semibold'>Não</p>
            </label>
          </div>
        </div>
      </div>
      <div className='flex justify-between bg-slate-50 transform border shadow-lg p-2'>
        <div>
          <h3 className='text-sm md:text-base font-medium text-gray-500'>
            Necessita caução?
          </h3>
          {/* <h3 className='text-xs md:text-sm font-medium text-gray-500'>
            incluindo suites
          </h3> */}
        </div>
        <div className='flex gap-2 justify-center items-center'>
          <div>
            <input
              {...register('deposit')}
              type='radio'
              id='deposit'
              name='hasDeposit'
              value='true'
              className='hidden peer'
              onChange={(e) => {
                setSearchParams((old) => {
                  old.set('deposit', e.target.value);
                  return old;
                });
                setValue('deposit', 'true');
              }}
              defaultChecked={searchParams.get('deposit') === 'true'}
            />
            <label
              htmlFor='deposit'
              className='flex w-full p-2 h-8 items-center text-emerald-700 bg-white border border-emerald-500 rounded cursor-pointer  peer-checked:border-black peer-checked:text-white peer-checked:bg-emerald-500  hover:bg-emerald-100 hover:bg-opacity-50'
            >
              <p className='w-full text-lg text-center font-semibold'>Sim</p>
            </label>
          </div>

          <div>
            <input
              {...register('deposit')}
              type='radio'
              id='notDeposit'
              name='hasDeposit'
              value='false'
              className='hidden peer'
              onChange={(e) => {
                setSearchParams((old) => {
                  old.set('deposit', e.target.value);
                  return old;
                });
                setValue('deposit', 'false');
              }}
              defaultChecked={searchParams.get('deposit') === 'false'}
            />
            <label
              htmlFor='notDeposit'
              className='flex w-full p-2 h-8 items-center text-emerald-700 bg-white border border-emerald-500 rounded cursor-pointer  peer-checked:border-black peer-checked:text-white peer-checked:bg-emerald-500  hover:bg-emerald-100 hover:bg-opacity-50'
            >
              <p className='w-full text-lg text-center font-semibold'>Não</p>
            </label>
          </div>
        </div>
      </div>
      <div className='flex justify-between bg-slate-50 transform border shadow-lg p-2'>
        <div>
          <h3 className='text-sm md:text-base font-medium text-gray-500'>
            Portaria 24h?
          </h3>
          {/* <h3 className='text-xs md:text-sm font-medium text-gray-500'>
            incluindo suites
          </h3> */}
        </div>
        <div className='flex gap-2 justify-center items-center'>
          <div>
            <input
              {...register('concierge')}
              type='radio'
              id='concierge'
              name='hasConcierge'
              value='true'
              className='hidden peer'
              onChange={(e) => {
                setSearchParams((old) => {
                  old.set('concierge', e.target.value);
                  return old;
                });
                setValue('concierge', 'true');
              }}
              defaultChecked={searchParams.get('concierge') === 'true'}
            />
            <label
              htmlFor='concierge'
              className='flex w-full p-2 h-8 items-center text-emerald-700 bg-white border border-emerald-500 rounded cursor-pointer  peer-checked:border-black peer-checked:text-white peer-checked:bg-emerald-500  hover:bg-emerald-100 hover:bg-opacity-50'
            >
              <p className='w-full text-lg text-center font-semibold'>Sim</p>
            </label>
          </div>

          <div>
            <input
              {...register('concierge')}
              type='radio'
              id='notConcierge'
              name='hasConcierge'
              value='false'
              className='hidden peer'
              onChange={(e) => {
                setSearchParams((old) => {
                  old.set('concierge', e.target.value);
                  return old;
                });
                setValue('concierge', 'false');
              }}
              defaultChecked={searchParams.get('concierge') === 'false'}
            />
            <label
              htmlFor='notConcierge'
              className='flex w-full p-2 h-8 items-center text-emerald-700 bg-white border border-emerald-500 rounded cursor-pointer  peer-checked:border-black peer-checked:text-white peer-checked:bg-emerald-500  hover:bg-emerald-100 hover:bg-opacity-50'
            >
              <p className='w-full text-lg text-center font-semibold'>Não</p>
            </label>
          </div>
        </div>
      </div>
      <div className='flex justify-between bg-slate-50 transform border shadow-lg p-2'>
        <div>
          <h3 className='text-sm md:text-base font-medium text-gray-500'>
            Aceita pet?
          </h3>
          {/* <h3 className='text-xs md:text-sm font-medium text-gray-500'>
            incluindo suites
          </h3> */}
        </div>
        <div className='flex gap-2 justify-center items-center'>
          <div>
            <input
              {...register('petFriendly')}
              type='radio'
              id='petFriendly'
              name='isPetFriendly'
              value='true'
              className='hidden peer'
              onChange={(e) => {
                setSearchParams((old) => {
                  old.set('petFriendly', e.target.value);
                  return old;
                });
                setValue('petFriendly', 'true');
              }}
              defaultChecked={searchParams.get('petFriendly') === 'true'}
            />
            <label
              htmlFor='petFriendly'
              className='flex w-full p-2 h-8 items-center text-emerald-700 bg-white border border-emerald-500 rounded cursor-pointer  peer-checked:border-black peer-checked:text-white peer-checked:bg-emerald-500  hover:bg-emerald-100 hover:bg-opacity-50'
            >
              <p className='w-full text-lg text-center font-semibold'>Sim</p>
            </label>
          </div>

          <div>
            <input
              {...register('petFriendly')}
              type='radio'
              id='notPetFriendly'
              name='isPetFriendly'
              value='false'
              className='hidden peer'
              onChange={(e) => {
                setSearchParams((old) => {
                  old.set('petFriendly', e.target.value);
                  return old;
                });
                setValue('petFriendly', 'false');
              }}
              defaultChecked={searchParams.get('petFriendly') === 'false'}
            />
            <label
              htmlFor='notPetFriendly'
              className='flex w-full p-2 h-8 items-center text-emerald-700 bg-white border border-emerald-500 rounded cursor-pointer  peer-checked:border-black peer-checked:text-white peer-checked:bg-emerald-500  hover:bg-emerald-100 hover:bg-opacity-50'
            >
              <p className='w-full text-lg text-center font-semibold'>Não</p>
            </label>
          </div>
        </div>
      </div>
      <div className='flex justify-between bg-slate-50 transform border shadow-lg p-2'>
        <div>
          <h3 className='text-sm md:text-base font-medium text-gray-500'>
            Imóvel mobiliado?
          </h3>
          {/* <h3 className='text-xs md:text-sm font-medium text-gray-500'>
            incluindo suites
          </h3> */}
        </div>
        <div className='flex gap-2 justify-center items-center'>
          <div>
            <input
              {...register('furnished')}
              type='radio'
              id='furnished'
              name='isFurnished'
              value='true'
              className='hidden peer'
              onChange={(e) => {
                setSearchParams((old) => {
                  old.set('furnished', e.target.value);
                  return old;
                });
                setValue('furnished', 'true');
              }}
              defaultChecked={searchParams.get('furnished') === 'true'}
            />
            <label
              htmlFor='furnished'
              className='flex w-full p-2 h-8 items-center text-emerald-700 bg-white border border-emerald-500 rounded cursor-pointer  peer-checked:border-black peer-checked:text-white peer-checked:bg-emerald-500  hover:bg-emerald-100 hover:bg-opacity-50'
            >
              <p className='w-full text-lg text-center font-semibold'>Sim</p>
            </label>
          </div>

          <div>
            <input
              {...register('furnished')}
              type='radio'
              id='notFurnished'
              name='isFurnished'
              value='false'
              className='hidden peer'
              onChange={(e) => {
                setSearchParams((old) => {
                  old.set('furnished', e.target.value);
                  return old;
                });
                setValue('furnished', 'false');
              }}
              defaultChecked={searchParams.get('furnished') === 'false'}
            />
            <label
              htmlFor='notFurnished'
              className='flex w-full p-2 h-8 items-center text-emerald-700 bg-white border border-emerald-500 rounded cursor-pointer  peer-checked:border-black peer-checked:text-white peer-checked:bg-emerald-500  hover:bg-emerald-100 hover:bg-opacity-50'
            >
              <p className='w-full text-lg text-center font-semibold'>Não</p>
            </label>
          </div>
        </div>
      </div>
      <div>
        <h3 className='mb-3 text-sm md:text-base font-medium text-gray-500'>
          {watch('action') === 'rent' ? 'Valor de aluguel:' : 'Valor de venda:'}
          {console.log(watch('action') === 'rent')}
        </h3>
        <div className='relative'>
          <input
            {...register('price')}
            type='number'
            inputMode='numeric'
            id='price'
            className='w-full h-10 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
            required
          />
          <p className='absolute top-0 end-0 h-full p-2 text-sm font-medium text-black cursor-default bg-slate-100 rounded-e-lg border  outline-emerald-500 active:before:bg-emerald-300'>
            ,00
          </p>
        </div>
      </div>
      <div>
        <h3 className='mb-3 text-sm md:text-base font-medium text-gray-500'>
          Condomínio: (R$/mês)
        </h3>
        <div className='relative'>
          <input
            {...register('monthlyTax')}
            type='number'
            inputMode='numeric'
            id='monthlyTax'
            className='w-full h-10 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
            required
          />
          <p className='absolute top-0 end-0 h-full p-2 text-sm font-medium text-black cursor-default bg-slate-100 rounded-e-lg border  outline-emerald-500 active:before:bg-emerald-300'>
            ,00
          </p>
        </div>
      </div>
      <div>
        <h3 className='mb-3 text-sm md:text-base font-medium text-gray-500'>
          IPTU: (R$/ano)
        </h3>
        <div className='relative'>
          <input
            {...register('iptu')}
            type='number'
            inputMode='numeric'
            id='iptu'
            className='w-full h-10 px-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
            required
          />
          <p className='absolute top-0 end-0 h-full p-2 text-sm font-medium text-black cursor-default bg-slate-100 rounded-e-lg border  outline-emerald-500 active:before:bg-emerald-300'>
            ,00
          </p>
        </div>
      </div>
      <div>
        <h3 className='mb-3 text-sm md:text-base font-medium text-gray-500'>
          Descrição do imóvel:
        </h3>
        <textarea
          {...register('description')}
          id='description'
          className='w-full  p-2 text-black duration-150 bg-white rounded-lg hover:bg-gray-100 active:shadow-lg outline-emerald-500'
          required
          rows={8}
        />
      </div>
      <div className='mx-auto my-8'>
        <button
          type='button'
          className='w-48 h-10 md:h-12 px-2 text-black font-semibold duration-150 bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg disabled:cursor-not-allowed disabled:bg-gray-300'
          onClick={handleNext}
        >
          Continuar
        </button>
      </div>
    </div>
  );
};

export default SecondStepForm;

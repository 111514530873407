export const serverMode = "production";

const development = "http://localhost:3333";
const developmentPhone = "http://192.168.2.100:3333";
const production = "https://api.rentia.app.br";
const testServer = "https://testapi.rentia.app.br";

export const satellite =
  "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
export const streets =
  "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}";
export const topo =
  "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}";

// SERVER URL
export const serverUrl =
  serverMode === "development"
    ? development
    : serverMode === "production"
    ? production
    : developmentPhone;

// Configuration file for the application

export const mapConfig = {
  map_min_zoom: 4,
  map_max_zoom: 18,
  map_default_zoom: 4,
  map_default_center: [-21.769942027474833, -43.329341532811995],
  map_tile_layer: streets,
  maxBounds: [
    [5.2695808, -34.5747978],
    [-33.8694284, -73.9830625],
  ],
  zoomControl: true,
};

export const drawingMapConfig = {
  map_min_zoom: 6,
  map_max_zoom: 18,
  map_default_zoom: 6,
  map_default_center: [-21.769942027474833, -43.329341532811995],
  map_tile_layer: streets,
  maxBounds: [
    [5.2695808, -34.5747978],
    [-33.8694284, -73.9830625],
  ],
};

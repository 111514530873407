import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  deleteProperty,
  disableProperty,
  usePropertiesList,
} from '../services/propertyService';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {
  AddHomeWork,
  BedOutlined,
  Delete,
  DirectionsCarFilledOutlined,
  Domain,
  DomainAdd,
  DomainDisabled,
  Edit,
  MapsHomeWork,
  Search,
  ShowerOutlined,
  SpaceDashboardOutlined,
} from '@mui/icons-material';
import { FixedSizeList as List } from 'react-window';
import { useMeasure, useMediaQuery } from '@uidotdev/usehooks';
import housePlaceholder from '../media/housePlaceholder.webp';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
import MyPropertyCard from '../components/Cards/MyPropertyCard';
import { useDispatch, useSelector } from 'react-redux';
import { drawerValuesActions } from '../store/drawerValues';
import Lottie from 'react-lottie';
import avatarAnimation from '../animations/Individual.json';
const responsive = {
  // superLargeDesktop: {
  //   // the naming can be any, depends on you.
  //   breakpoint: { max: 4000, min: 3000 },
  //   items: 1,
  // },
  // desktop: {
  //   breakpoint: { max: 3000, min: 1024 },
  //   items: 1,
  // },
  // tablet: {
  //   breakpoint: { max: 1024, min: 464 },
  //   items: 1,
  // },
  mobile: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

const MyPropertiesPage = () => {
  const [filterText, setFilterText] = useState('');
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [avatarAnimationPaused, setAvatarAnimationPaused] = useState(true);
  const [propertiesListRef, { width, height }] = useMeasure();
  const dispatch = useDispatch();
  const { userId } = useSelector((state) => state.loginValues);
  const isLargeDevice = useMediaQuery('only screen and (min-width : 1200px)');

  const navigate = useNavigate();
  const {
    propertiesList = [],
    isLoading,
    isError,
    isValidating,
  } = usePropertiesList({
    revalidateOnFocus: false,
    revalidateIfStale: true,
    revalidateOnReconnect: false,
  });

  const handlePropertiesFilter = (e) => {
    setFilterText(e.target.value);
    console.log('Valor: ', e.target.value);
    console.log(filterText);
  };

  const handleDisableProperty = (id, status) => {
    console.log('Desativar propriedade: ', id);
    disableProperty(id)
      .then(() => {
        console.log('Propriedade desativada com sucesso');
        toast.success(
          status === 'active'
            ? 'Imóvel desativado com sucesso'
            : 'Imóvel ativado com sucesso',
          {
            autoClose: 1500,
            position: 'bottom-center',
          }
        );
      })
      .catch((error) => {
        console.log('Erro ao desativar a propriedade: ', error);
        toast.error(
          status === 'active'
            ? 'Erro ao desativar o imóvel'
            : 'Erro ao ativar o imóvel',
          {
            autoClose: 1500,
            position: 'bottom-center',
          }
        );
      })
      .finally(() => {
        console.log('Finalizando a requisição');
        mutate('propertiesList');
      });
  };

  const handleDeleteProperty = (id) => {
    console.log('Deletar propriedade: ', id);
    deleteProperty(id)
      .then(() => {
        console.log('Propriedade deletada com sucesso');
        toast.success('Imóvel excluído com sucesso', {
          autoClose: 1500,
          position: 'bottom-center',
        });
      })
      .catch((error) => {
        console.log('Erro ao desativar a propriedade: ', error);
        toast.error('Erro ao excluir o imóvel', {
          autoClose: 1500,
          position: 'bottom-center',
        });
      })
      .finally(() => {
        console.log('Finalizando a requisição');
        mutate('propertiesList');
        setIsDeleteDialogOpen(false);
      });
  };

  const handleDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  useEffect(() => {
    console.log('Width:', width);
    console.log('Height:', height);
  }, [width, height]);

  if (isLoading || isValidating) {
    return (
      <Backdrop open={isLoading} style={{ zIndex: 99 }}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  if ((propertiesList && propertiesList.message) || !userId) {
    return (
      <div className='flex flex-col items-center justify-center gap-4 h-full p-6'>
        <MapsHomeWork className='w-16 h-16 ' />
        <h1 className='text-lg md:text-xl lg:text-2xl font-bold'>
          Você não está conectado.
        </h1>
        <p className='text-sm md:text-base lg:text-lg text-center'>
          Clique no botão abaixo para entrar em sua conta ou criar uma nova
        </p>
        <Button
          variant='contained'
          sx={{ backgroundColor: '#10B981' }}
          startIcon={
            <Lottie
              options={{
                loop: true,
                autoplay: false,
                animationData: avatarAnimation,
              }}
              height={25}
              width={25}
              isStopped={avatarAnimationPaused}
            />
          }
          onClick={() => dispatch(drawerValuesActions.setLoginDrawer(true))}
        >
          Login
        </Button>
      </div>
    );
  }

  if (isError) {
    return <div>Erro ao carregar os imóveis</div>;
  }

  if (!propertiesList || propertiesList?.length === 0) {
    return (
      <div className='flex flex-col items-center justify-center gap-4 h-full p-6'>
        <MapsHomeWork className='w-16 h-16 ' />
        <h1 className='text-lg md:text-xl lg:text-2xl font-bold'>
          Nenhum imóvel encontrado
        </h1>
        <p className='text-sm md:text-base lg:text-lg text-center'>
          Clique no botão abaixo para adicionar um novo imóvel
        </p>
        <Button
          variant='contained'
          color='success'
          startIcon={<AddHomeWork />}
          onClick={() => navigate('/minhas-propriedades/adicionar')}
        >
          Adicionar Imóvel
        </Button>
      </div>
    );
  }

  const Row = ({ index, style }) => {
    const propertyItem = propertiesList.filter((property) => {
      if (
        property.description.toLowerCase().includes(filterText.toLowerCase()) ||
        property._id.toLowerCase().includes(filterText.toLowerCase()) ||
        property.address.toLowerCase().includes(filterText.toLowerCase())
      ) {
        return property;
      }
    })[index];

    return (
      <div
        style={{
          ...style,
          paddingLeft: '0.8rem',
          paddingRight: '0.8rem',
        }}
        id='rowId'
        key={propertyItem._id}
        className='flex justify-center items-center'
      >
        <MyPropertyCard property={propertyItem} />
      </div>
    );
  };

  return (
    <div className='flex flex-col pt-4 gap-4 items-center px-4 h-fit-content w-full'>
      <div>
        <h1 className='text-black font-extrabold  text-center text-xl md:text-3xl'>
          Meus Imóveis
        </h1>
      </div>

      <div className='flex my-4'>
        <input
          className='border w-72 border-emerald-700 rounded-md p-2'
          placeholder='Filtre por endereço, descrição ou identificador'
          type='text'
          onChange={handlePropertiesFilter}
        />
      </div>
      <div className='flex items-center justify-center w-11/12 xs:w-11/12 sm:w-4/5 md:w-4/5 lg:w-3/6'>
        <h1 className='font-semibold text-md lg:text-xl'>{`${
          propertiesList.filter((property) => {
            if (
              property.description
                .toLowerCase()
                .includes(filterText.toLowerCase()) ||
              property._id.toLowerCase().includes(filterText.toLowerCase()) ||
              property.address.toLowerCase().includes(filterText.toLowerCase())
            ) {
              return property;
            }
          }).length
        } ${
          propertiesList.filter((property) => {
            if (
              property.description
                .toLowerCase()
                .includes(filterText.toLowerCase()) ||
              property._id.toLowerCase().includes(filterText.toLowerCase()) ||
              property.address.toLowerCase().includes(filterText.toLowerCase())
            ) {
              return property;
            }
          }).length > 1
            ? 'imóveis encontrados'
            : 'imóvel encontrado'
        }`}</h1>
      </div>

      {!isLoading && !isError && propertiesList && (
        <div
          className='w-full lg:w-1/3 h-[500px] flex flex-col mt-8 justify-center items-center border border-gray-300 rounded-lg'
          ref={propertiesListRef}
        >
          {height && (
            <List
              height={height} // Adjust the height as needed
              itemCount={
                propertiesList.filter((property) => {
                  if (
                    property.description
                      .toLowerCase()
                      .includes(filterText.toLowerCase()) ||
                    property._id
                      .toLowerCase()
                      .includes(filterText.toLowerCase()) ||
                    property.address
                      .toLowerCase()
                      .includes(filterText.toLowerCase())
                  ) {
                    return property;
                  }
                }).length
              }
              itemSize={200} // Adjust the item size as needed
              width={'100%'}
            >
              {Row}
            </List>
          )}
        </div>
      )}

      <div className='mb-6'>
        <button
          type='button'
          className=' h-10 md:h-12 px-2 text-black font-semibold duration-150 bg-emerald-400 rounded-lg hover:bg-emerald-500 active:shadow-lg disabled:cursor-not-allowed disabled:bg-gray-300'
          onClick={() => navigate('/minhas-propriedades/adicionar')}
        >
          <div className='flex gap-4 p-2'>
            <DomainAdd />
            <p>Adicionar Propriedade</p>
          </div>
        </button>
      </div>
    </div>
  );
};

export default MyPropertiesPage;

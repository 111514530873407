import React, { useEffect, useRef, useState } from "react";

import L from "leaflet";
import { useMap } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  useGetPropertiesByGeometry,
  useGetPropertiesByMapBounds,
} from "../../services/propertyService";
import { toast } from "react-toastify";
import blueMarker from "../../media/icons/blueMarker.svg";
import redMarker from "../../media/icons/redMarker.svg";
import markerShadow from "../../media/icons/marker-shadow.png";
import { useMediaQuery } from "@uidotdev/usehooks";
import SelectedPropertyDrawer from "../Drawers/SelectedPropertyDrawer.js";
import { propertyValuesActions } from "../../store/propertyValues.js";
import { createAlert } from "../../services/alertService.js";

import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

import {
  requestNotificationPermission,
  subscribeToPushService,
} from "../../utils/pushNotifications.js";
import SearchFiltersDrawer from "../Drawers/SearchFiltersDrawer.js";
import CreateAlertDialog from "../Dialogs/CreateAlertDialog.js";
import { mapValuesActions } from "../../store/mapValues.js";
import { mutate } from "swr";
import { markerValuesActions } from "../../store/markerValues.js";
import GeolocationDialog from "../Dialogs/GeolocationDialog.js";
import * as turf from "@turf/turf";

const WrapperSearchResultsComponent = ({
  isFiltersDrawerOpen,
  setIsFiltersDrawerOpen,
  isCreateAlertDialogOpen,
  setIsCreateAlertDialogOpen,
}) => {
  const map = useMap();

  let markers = L.markerClusterGroup({
    // spiderfyOnMaxZoom: true,
    removeOutsideVisibleBounds: true,
    zoomToBoundsOnClick: true,
    spiderfyDistanceMultiplier: 2,
    spiderfyOnEveryZoom: false,
    chunkedLoading: true,
    chunkInterval: 300,
    // disableClusteringAtZoom: 18,
  });

  // console.log('Markers:', markers);

  const timerRefBounds = useRef(null);
  const abortControllerRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const { selectedSearchResult } = useSelector((state) => state.objectValues);
  const { selectedProperty, propertiesCounter } = useSelector(
    (state) => state.propertyValues
  );

  const { mapZoom, mapBounds } = useSelector((state) => state.mapValues);
  const { selectedMarker } = useSelector((state) => state.markerValues);
  const dispatch = useDispatch();
  const [isPropertyDrawerOpen, setIsPropertyDrawerOpen] = useState(false);
  const [isGeolocationDialogOpen, setIsGeolocationDialogOpen] = useState(false);

  const filters = {};
  Array.from(searchParams.entries()).forEach((item) => {
    filters[item[0]] = item[1];
  });

  const {
    propertiesByGeometry,
    isLoadingPropertiesByGeometry,
    isErrorPropertiesByGeometry,
    isValidatingPropertiesByGeometry,
  } = useGetPropertiesByGeometry(
    selectedSearchResult,
    filters,
    filters["action"] || "rent",
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const {
    propertiesByMapBounds,
    isLoadingPropertiesByMapBounds,
    isErrorPropertiesByMapBounds,
    isValidatingPropertiesByMapBounds,
  } = useGetPropertiesByMapBounds(
    mapZoom,
    mapBounds,
    selectedSearchResult,
    filters,
    filters["action"] || "rent",
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 2000,
    }
  );

  const redMarkerIcon = L.icon({
    iconUrl: redMarker,
    shadowUrl: markerShadow,
    iconSize: [20, 30],
    shadowSize: [33, 30],
    iconAnchor: [12, 41],
    popupAnchor: [-2, -34],
    tooltipAnchor: [16, -28],
  });

  const blueMarkerIcon = L.icon({
    iconUrl: blueMarker,
    shadowUrl: markerShadow,
    iconSize: [20, 30],
    shadowSize: [33, 30],
    iconAnchor: [12, 41],
    popupAnchor: [-2, -34],
    tooltipAnchor: [16, -28],
  });

  const isExtraSmallDevice = useMediaQuery(
    "only screen and (max-width : 350px)"
  );

  const clearLayers = () => {
    map.eachLayer((layer) => {
      if (
        layer instanceof L.Circle ||
        layer instanceof L.Polyline ||
        layer instanceof L.Polygon ||
        layer instanceof L.Marker ||
        layer instanceof L.MarkerClusterGroup
      ) {
        map.removeLayer(layer);
      }
    });
  };

  const handleCloseFiltersDrawer = () => {
    setIsFiltersDrawerOpen(false);
  };

  const handleCloseCreateAlertDialog = () => {
    setIsCreateAlertDialogOpen(false);
  };

  const handleCreateAlert = async () => {
    if (!selectedSearchResult) {
      toast.error("Nenhuma área ou endereço foi selecionado.", {
        style: { zIndex: 1000 },
        position: "bottom-center",
      });
      setIsCreateAlertDialogOpen(false);
      return;
    }
    const permission = await requestNotificationPermission();

    if (permission !== "granted") {
      toast.error("Ative as notificações para criar alertas", {
        style: { zIndex: 1000 },
        position: "bottom-center",
      });
      setIsCreateAlertDialogOpen(false);
      return;
    }

    const subscription = await subscribeToPushService();

    const filters = [];

    Array.from(searchParams.entries()).forEach((item) => {
      filters.push({ key: item[0], value: item[1] });
    });

    const alert = {
      selectedSearchResult,
      filters,
    };

    createAlert(alert)
      .then((data) => {
        console.log(data);
        toast.success("Alerta criado com sucesso", {
          style: { zIndex: 1000 },
          position: "bottom-center",
        });
        setIsCreateAlertDialogOpen(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Erro ao criar alerta", {
          style: { zIndex: 1000 },
          position: "bottom-center",
        });
      });

    console.log("filters:", filters);
  };

  const saveSearchToLocalStorage = () => {
    const filters = {};
    Array.from(searchParams.entries()).forEach((item) => {
      filters[item[0]] = item[1];
    });

    const recentSearch = {
      selectedSearchResult: selectedSearchResult,
      action: searchParams.get("action"),
      filters: filters,
      dateCreate: new Date().toLocaleDateString(),
    };

    // Serialize the recentSearch object to a string
    const serializedRecentSearch = JSON.stringify(recentSearch);

    // Retrieve the existing recent searches from localStorage
    const serializedRecentSearches = localStorage.getItem("recentSearches");
    let recentSearches = serializedRecentSearches
      ? JSON.parse(serializedRecentSearches)
      : [];

    // Check if the recentSearch already exists in the array
    const searchExists = recentSearches.some(
      (search) => JSON.stringify(search) === serializedRecentSearch
    );

    // If the search already exists, do not add it again
    if (searchExists) {
      return;
    }

    // Add the new search to the beginning of the array
    recentSearches.unshift(recentSearch);

    // If the array length exceeds 5, remove the last item
    if (recentSearches.length > 5) {
      recentSearches.pop();
    }

    // Serialize the updated array and store it back in localStorage
    localStorage.setItem("recentSearches", JSON.stringify(recentSearches));
  };

  const handleGeolocationPermission = async () => {
    if (!selectedSearchResult) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("Current position:", position);
          map.setView([latitude, longitude], 13);
          localStorage.setItem("geoLocationPermission", "granted");
        },
        (error) => {
          console.error("Geolocation error:", error);
          localStorage.setItem("geoLocationPermission", "denied");
          // toast.info('Ative a localização para uma melhor experiência');
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    }
    setIsGeolocationDialogOpen(false);
  };

  // reset property values on unmount
  useEffect(() => {
    return () => {
      dispatch(propertyValuesActions.setSelectedProperty(null));
      dispatch(propertyValuesActions.setSelectedPropertyId(null));
    };
  }, []);

  // handle requests cancellation on map bounds change
  useEffect(() => {
    // Create a new AbortController for each request
    abortControllerRef.current = new AbortController();

    return () => {
      // Cleanup the AbortController on component unmount
      // console.log('AbortController cleanup');
      abortControllerRef.current.abort();
    };
  }, [mapBounds]);

  // Set mode states on mount
  useEffect(() => {
    map.whenReady(() => {
      map.on("locationfound", (e) => {
        // console.log('Location found:', e);
        if (!selectedSearchResult) {
          e.target.setZoom(13);
        }
      });

      map.on("locationerror", (e) => {
        // console.log('Location error:', e);
      });

      map.on("moveend", () => {
        // console.log('Map bounds:', map.getBounds());

        if (timerRefBounds.current) {
          clearTimeout(timerRefBounds.current);
        }
        timerRefBounds.current = setTimeout(() => {
          dispatch(mapValuesActions.setMapZoom(map.getZoom()));
          dispatch(
            mapValuesActions.setMapBounds([
              [
                map.getBounds().getSouthWest().lng,
                map.getBounds().getSouthWest().lat,
              ],
              [
                map.getBounds().getNorthEast().lng,
                map.getBounds().getNorthEast().lat,
              ],
            ])
          );
        }, 300);
      });
    });
    return () => {
      map.off("zoomend");
      map.off("locationfound");
      map.off("locationerror");
      map.off("moveend");
    };
  }, []);

  // handle properties by geometry mutation
  useEffect(() => {
    if (!selectedSearchResult) {
      console.log("No selectedSearchResult useeffect");
      return;
    }
    console.log("SelectedSearchResult useeffect:");
    mutate("propertiesByGeometry");
  }, [selectedSearchResult, searchParams]);

  // handle search radius change
  useEffect(() => {
    if (searchParams.get("searchRadius")) {
      map.eachLayer((layer) => {
        if (layer instanceof L.Circle) {
          layer.setRadius(searchParams.get("searchRadius") * 1000);
          map.flyToBounds(layer.getBounds(), {
            animate: true,
          });
        }
      });
    }
  }, [searchParams.get("searchRadius")]);

  // handle properties by mapBounds mutation
  useEffect(() => {
    if (!mapZoom || !mapBounds || selectedSearchResult) {
      console.log("No mapZoom or mapBounds or selectedSearchResult useeffect");
      return;
    }
    console.log("MapZoom or mapBounds useeffect:");
    mutate("propertiesByMapBounds");
  }, [mapZoom, mapBounds, selectedSearchResult, searchParams]);

  // handle properties by geometry
  useEffect(() => {
    if (isErrorPropertiesByGeometry) {
      toast.error("Erro ao buscar imóveis", {
        style: { zIndex: 1000 },
        position: "bottom-center",
      });
      console.log("Error:", isErrorPropertiesByGeometry);
    }
    // if (
    //   !propertiesByGeometry &&
    //   !isValidatingPropertiesByGeometry &&
    //   !isLoadingPropertiesByGeometry &&
    //   !propertiesByGeometry?.message
    // ) {
    //   return;
    // }
    if (
      !isLoadingPropertiesByGeometry &&
      !isErrorPropertiesByGeometry &&
      !isValidatingPropertiesByGeometry &&
      propertiesByGeometry &&
      !propertiesByGeometry.message &&
      !propertiesByGeometry.error &&
      selectedSearchResult
    ) {
      console.log("Properties: ", propertiesByGeometry);
      dispatch(
        propertyValuesActions.setPropertiesCounter(propertiesByGeometry.length)
      );

      map.eachLayer((layer) => {
        if (
          layer instanceof L.Marker ||
          layer instanceof L.MarkerClusterGroup
        ) {
          map.removeLayer(layer);
        }
      });

      saveSearchToLocalStorage();

      const markersArray = [];

      propertiesByGeometry.forEach((property) => {
        const marker = L.marker(
          [property.location.coordinates[1], property.location.coordinates[0]],
          {
            icon: redMarkerIcon,
            propertyId: property._id,
          }
        );
        marker.on("click", (e) => {
          dispatch(
            markerValuesActions.setSelectedMarker({
              id: marker.options.propertyId,
              timestamp: Date.now(),
            })
          );
          dispatch(propertyValuesActions.setSelectedProperty(property));
          dispatch(propertyValuesActions.setSelectedPropertyId(property._id));
          setIsPropertyDrawerOpen(true);
        });

        marker
          .bindTooltip(
            new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(property.totalPrice),
            {
              permanent: true,
              direction: "top",
              offset: [-17, -20],
              className: "tooltip",
            }
          )
          .openTooltip();
        markersArray.push(marker);
      });

      markers.addLayers(markersArray);
      map.addLayer(markers);
    }
  }, [
    isLoadingPropertiesByGeometry,
    isErrorPropertiesByGeometry,
    isValidatingPropertiesByGeometry,
    propertiesByGeometry,
    selectedSearchResult,
  ]);

  // handle properties by map bounds
  useEffect(() => {
    if (
      !isLoadingPropertiesByMapBounds &&
      !isErrorPropertiesByMapBounds &&
      !isValidatingPropertiesByMapBounds &&
      propertiesByMapBounds &&
      !propertiesByMapBounds.message &&
      !selectedSearchResult
    ) {
      console.log("Properties: ", propertiesByMapBounds);
      dispatch(
        propertyValuesActions.setPropertiesCounter(propertiesByMapBounds.length)
      );
      clearLayers();

      const markersArray = [];
      propertiesByMapBounds.forEach((property) => {
        const marker = L.marker(
          [property.location.coordinates[1], property.location.coordinates[0]],
          {
            icon: redMarkerIcon,
            propertyId: property._id,
          }
        );
        marker.on("click", (e) => {
          dispatch(
            markerValuesActions.setSelectedMarker({
              id: marker.options.propertyId,
              timestamp: Date.now(),
            })
          );
          dispatch(propertyValuesActions.setSelectedProperty(property));
          dispatch(propertyValuesActions.setSelectedPropertyId(property._id));
          setIsPropertyDrawerOpen(true);
        });

        marker
          .bindTooltip(
            new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(property.totalPrice),
            {
              permanent: true,
              direction: "top",
              offset: [-17, -20],
              className: "tooltip",
            }
          )
          .openTooltip();
        markersArray.push(marker);
      });
      markers.addLayers(markersArray);
      map.addLayer(markers);
    }
  }, [
    isLoadingPropertiesByMapBounds,
    isErrorPropertiesByMapBounds,
    propertiesByMapBounds,
    selectedSearchResult,
  ]);

  //handle marker click event to change the selected marker icon
  useEffect(() => {
    map.eachLayer((layer, index) => {
      if (layer instanceof L.Marker && !(layer instanceof L.MarkerCluster)) {
        if (selectedMarker) {
          if (layer.options.icon.options.iconUrl === blueMarker) {
            layer.setIcon(redMarkerIcon);
          }

          if (layer.options.propertyId === selectedMarker.id) {
            layer.setIcon(blueMarkerIcon);
          }
        } else {
          if (layer.options.icon.options.iconUrl === blueMarker) {
            layer.setIcon(redMarkerIcon);
          }
        }
      }
    });
  }, [selectedMarker]);

  // check geolocation permission on mount
  useEffect(() => {
    if (selectedSearchResult) {
      return;
    }
    if (!localStorage.getItem("geoLocationPermission")) {
      setTimeout(() => {
        setIsGeolocationDialogOpen(true);
      }, 2000);
    }

    if (localStorage.getItem("geoLocationPermission") === "granted") {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("Current position:", position);
          map.setView([latitude, longitude], 13);
        },
        (error) => {
          console.error("Geolocation error:", error);
          toast.info("Ative a localização para uma melhor experiência", {
            style: { zIndex: 1000 },
            position: "bottom-center",
          });
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    }
  }, []);

  useEffect(() => {
    if (selectedSearchResult && selectedSearchResult.properties.filters) {
      const filtersToUpdate = Object.entries(
        selectedSearchResult.properties.filters
      ).filter(
        ([key, value]) =>
          key !== "city" &&
          key !== "neighborhood" &&
          key !== "address" &&
          value !== undefined &&
          value !== null &&
          value !== ""
      );

      setSearchParams((prev) => {
        filtersToUpdate.forEach(([key, value]) => {
          prev.set(key, value);
        });
        return prev;
      });

      // If there's a searchRadius in the filters but none in the URL, set a default
      if (
        !searchParams.get("searchRadius") &&
        selectedSearchResult.geometry.type !== "Polygon"
      ) {
        setSearchParams((prev) => {
          prev.set("searchRadius", "0.5");
          return prev;
        });
      }
    }
  }, [selectedSearchResult]);

  // Updated selectedSearchResult effect
  useEffect(() => {
    if (selectedSearchResult?.properties?.filters) {
      // First, clear any existing filters that might conflict
      const newParams = new URLSearchParams();

      // Preserve the action parameter if it exists
      const currentAction = searchParams.get("action");
      if (currentAction) {
        newParams.set("action", currentAction);
      } else {
        newParams.set("action", "rent"); // default value
      }

      // Add all valid filters from selectedSearchResult
      Object.entries(selectedSearchResult.properties.filters).forEach(
        ([key, value]) => {
          if (
            key !== "city" &&
            key !== "neighborhood" &&
            key !== "address" &&
            value !== undefined &&
            value !== null &&
            value !== ""
          ) {
            newParams.set(key, value);
          }
        }
      );

      // Set default searchRadius for non-polygon searches
      if (
        selectedSearchResult.geometry.type !== ("Polygon" || "MultiPolygon") &&
        !newParams.get("searchRadius")
      ) {
        newParams.set("searchRadius", "0.5");
      }

      // Replace all params at once to avoid multiple renders
      setSearchParams(newParams);
    }
  }, [selectedSearchResult]);

  // handle selectedSearchResult change to create geojson on the map
  useEffect(() => {
    markers.clearLayers();
    clearLayers();

    dispatch(propertyValuesActions.setPropertiesCounter(0));
    if (!selectedSearchResult) {
      return;
    }

    if (selectedSearchResult.geometry.type === "Point") {
      const geoJson = L.geoJSON(selectedSearchResult, {
        pointToLayer: function (feature, latlng) {
          return L.circle(latlng, {
            radius: (searchParams.get("searchRadiums") || 0.1) * 1000,
          });
        },
      });
      geoJson.addTo(map);
      map.flyToBounds(geoJson.getBounds(), {
        animate: false,
      });

      setSearchParams((old) => {
        old.set("searchRadius", searchParams.get("searchRadius") || 0.1);
        return old;
      });
    }
    if (selectedSearchResult.geometry.type === "LineString") {
      const center = turf.center(selectedSearchResult);
      console.log("Center:", center);

      const geoJsonLine = L.geoJSON(selectedSearchResult, {
        style: function (feature) {
          //color red
          return { color: "black" };
        },
      });
      const geoJsonCircle = L.geoJSON(center, {
        pointToLayer: function (feature, latlng) {
          return L.circle(latlng, {
            radius: (searchParams.get("searchRadius") || 0.1) * 1000,
          });
        },
      });

      geoJsonLine.addTo(map);
      geoJsonCircle.addTo(map);

      map.flyToBounds(geoJsonCircle.getBounds(), {
        animate: false,
      });

      setSearchParams((old) => {
        old.set("searchRadius", searchParams.get("searchRadius") || 0.1);
        return old;
      });
    }
    if (selectedSearchResult.geometry.type === "MultiLineString") {
      const polyline = L.polyline(
        selectedSearchResult.geometry.coordinates.map((linestring) =>
          linestring.map((coord) => [coord[1], coord[0]])
        ),
        {
          color: "red",
          opacity: 0.5,
        }
      ).addTo(map);
      const center = polyline.getCenter();

      console.log("searchRadius: ", searchParams.get("searchRadius"));
      const searchRadius = searchParams.get("searchRadius") * 1000;
      const circle = L.circle(center, {
        radius: searchRadius || 100,
      }).addTo(map);
      map.flyToBounds(circle.getBounds(), {
        animate: false,
      });
    }
    if (selectedSearchResult.geometry.type === "Polygon") {
      const geoJson = L.geoJSON(selectedSearchResult, {
        style: {
          // stroke: true,
          weight: 1,
          fillOpacity: 0.4,
        },
      }).addTo(map);

      map.flyToBounds(geoJson.getBounds(), {
        animate: false,
      });

      if (selectedSearchResult.geometry.coordinates[0].length > 1000) {
        toast.warning("Área muito grande. Sua busca pode demorar um pouco.", {
          style: { zIndex: 1000 },
          position: "bottom-center",
        });
      }
    }
    if (selectedSearchResult.geometry.type === "MultiPolygon") {
      const geoJson = L.geoJSON(selectedSearchResult).addTo(map);

      map.flyToBounds(geoJson.getBounds(), {
        animate: false,
      });

      if (selectedSearchResult.geometry.coordinates[0][0].length > 1000) {
        toast.warning("Área muito grande. Sua busca pode demorar um pouco.", {
          style: { zIndex: 1000 },
          position: "bottom-center",
        });
      }
    }

    return () => {
      markers.clearLayers();
      clearLayers();
    };
  }, [selectedSearchResult]);

  return (
    <div>
      <GeolocationDialog
        handleGeolocationPermission={handleGeolocationPermission}
        isGeolocationDialogOpen={isGeolocationDialogOpen}
      />
      <SearchFiltersDrawer
        isFiltersDrawerOpen={isFiltersDrawerOpen}
        setIsFiltersDrawerOpen={setIsFiltersDrawerOpen}
        handleCloseFiltersDrawer={handleCloseFiltersDrawer}
      />
      <SelectedPropertyDrawer
        isPropertyDrawerOpen={isPropertyDrawerOpen}
        setIsPropertyDrawerOpen={setIsPropertyDrawerOpen}
        selectedProperty={selectedProperty}
        isExtraSmallDevice={isExtraSmallDevice}
        handleCreateAlert={handleCreateAlert}
        map={map}
        markers={markers}
        redMarker={redMarker}
        blueMarker={blueMarker}
      />
      <CreateAlertDialog
        isCreateAlertDialogOpen={isCreateAlertDialogOpen}
        handleCloseCreateAlertDialog={handleCloseCreateAlertDialog}
        handleCreateAlert={handleCreateAlert}
      />
    </div>
  );
};

export default WrapperSearchResultsComponent;

import React, { useState } from 'react';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {
  BedOutlined,
  Delete,
  DirectionsCarFilledOutlined,
  Domain,
  DomainDisabled,
  Edit,
  Search,
  ShowerOutlined,
  SpaceDashboardOutlined,
} from '@mui/icons-material';
import housePlaceholder from '../../media/housePlaceholder.webp';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useMediaQuery } from '@uidotdev/usehooks';
import {
  deleteProperty,
  disableProperty,
} from '../../services/propertyService';
import { toast } from 'react-toastify';
import { mutate } from 'swr';
import { useNavigate } from 'react-router-dom';

const responsive = {
  // superLargeDesktop: {
  //   // the naming can be any, depends on you.
  //   breakpoint: { max: 4000, min: 3000 },
  //   items: 1,
  // },
  // desktop: {
  //   breakpoint: { max: 3000, min: 1024 },
  //   items: 1,
  // },
  // tablet: {
  //   breakpoint: { max: 1024, min: 464 },
  //   items: 1,
  // },
  mobile: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

const MyPropertyCard = ({ property, style }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const isLargeDevice = useMediaQuery('only screen and (max-width : 1200px)');
  const navigate = useNavigate();

  const handleDisableProperty = (id, status) => {
    console.log('Desativar propriedade: ', id);
    disableProperty(id)
      .then(() => {
        console.log('Propriedade desativada com sucesso');
        toast.success(
          status === 'active'
            ? 'Imóvel desativado com sucesso'
            : 'Imóvel ativado com sucesso',
          {
            autoClose: 1500,
            position: 'bottom-center',
          }
        );
      })
      .catch((error) => {
        console.log('Erro ao desativar a propriedade: ', error);
        toast.error(
          status === 'active'
            ? 'Erro ao desativar o imóvel'
            : 'Erro ao ativar o imóvel',
          {
            autoClose: 1500,
            position: 'bottom-center',
          }
        );
      })
      .finally(() => {
        console.log('Finalizando a requisição');
        mutate('propertiesList');
      });
  };

  const handleDeleteProperty = (id) => {
    console.log('Deletar propriedade: ', id);
    deleteProperty(id)
      .then(() => {
        console.log('Propriedade deletada com sucesso');
        toast.success('Imóvel excluído com sucesso', {
          autoClose: 1500,
          position: 'bottom-center',
        });
      })
      .catch((error) => {
        console.log('Erro ao desativar a propriedade: ', error);
        toast.error('Erro ao excluir o imóvel', {
          autoClose: 1500,
          position: 'bottom-center',
        });
      })
      .finally(() => {
        console.log('Finalizando a requisição');
        mutate('propertiesList');
        setIsDeleteDialogOpen(false);
      });
  };

  const handleDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  return (
    <div
      key={property._id}
      className={`${
        property.status === 'active' ? 'bg-slate-50' : 'bg-slate-200'
      } w-11/12 xs:w-11/12 sm:w-4/5 md:w-4/5 p-1 lg:w-full flex flex-col xs:flex-row   hover:bg-emerald-100 shadow-lg border border-emerald-900 rounded duration-500`}
    >
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Excluir Imóvel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Você tem certeza que deseja excluir esse imóvel?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => setIsDeleteDialogOpen(false)}
            className='bg-gray-400 text-white font-bold px-4 py-2 rounded-md hover:bg-gray-500'
          >
            Cancelar
          </button>
          <button
            onClick={() => {
              handleDeleteProperty(property._id);
            }}
            className='bg-red-400 text-white font-bold px-4 py-2 rounded-md hover:bg-red-500'
          >
            Excluir
          </button>
        </DialogActions>
      </Dialog>
      {/* <Carousel
        responsive={responsive}
        infinite={true}
        slidesToSlide={1}
        className='w-full xs:w-1/3 h-1/2 xs:h-26'
        swipeable
        draggable
        keyBoardControl={true}
      >
        {[...property.images]
          .sort((a, b) => {
            if (a.isCover) return -1;
            if (b.isCover) return 1;
          })
          .map((image) => (
            <img
              key={image.name}
              src={image.url || housePlaceholder}
              alt='property'
              className='w-full h-full object-fill'
            />
          ))}
      </Carousel> */}

      <div className='flex flex-col justify-between pt-2 px-2  flex-grow xs:w-2/3 gap-1 '>
        <div>
          {/* <p className='text-base sm:text-lg md:text-xl lg:text-xl font-bold tracking-tight text-emerald-700 truncate'>
            {property.description}
          </p> */}
          <p className='font-medium text-sm sm:text-base md:text-lg text-gray-500 truncate'>{`${property.address} - ${property.houseNumber} - ${property.neighborhood}`}</p>
        </div>
        {/* <div className='flex justify-between pr-1 gap-1'>
          <div className='flex items-center gap-1'>
            <SpaceDashboardOutlined className='w-6 h-6 text-gray-500' />
            <p className='text-sm text-gray-500 font-medium'>{`${property.area} m²`}</p>
          </div>
          <div className='flex items-center gap-1'>
            <BedOutlined className='w-6 h-6 text-gray-500' />
            <p className='text-sm text-gray-500 font-medium'>{`${
              property.bedrooms
            } ${!isLargeDevice ? 'quartos' : ''}`}</p>
          </div>
          <div className='flex items-center gap-1'>
            <ShowerOutlined className='w-6 h-6 text-gray-500' />
            <p className='text-sm text-gray-500 font-medium'>{`${
              property.bathrooms
            } ${!isLargeDevice ? 'banheiros' : ''}`}</p>
          </div>
          <div className='flex items-center gap-1'>
            <DirectionsCarFilledOutlined className='w-6 h-6 text-gray-500' />
            <p className='text-sm text-gray-500 font-medium'>{`${
              property.parkingSlots
            } ${!isLargeDevice ? 'vagas' : ''}`}</p>
          </div>
        </div> */}
        <div>
          <p className='text-sm'>{`${
            property.action === 'sale' ? 'Preço:' : 'Aluguel (R$/mês):'
          }`}</p>
          <p className='font-bold'>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(property.totalPrice)}
          </p>
        </div>
        <div className='flex justify-center xs:justify-start gap-4'>
          <div className='hover:scale-125 duration-200'>
            <Tooltip title='Ver detalhes' arrow>
              <IconButton onClick={() => navigate(`/imovel/${property._id}`)}>
                <Search />
              </IconButton>
            </Tooltip>
          </div>
          <div className='hover:scale-125 duration-200'>
            <Tooltip title='Editar' arrow>
              <IconButton>
                <Edit />
              </IconButton>
            </Tooltip>
          </div>
          <div className='hover:scale-125 duration-200'>
            <Tooltip
              title={property.status === 'active' ? 'Desativar' : 'Ativar'}
              arrow
            >
              <IconButton
                onClick={() =>
                  handleDisableProperty(property._id, property.status)
                }
              >
                {property.status === 'active' ? <DomainDisabled /> : <Domain />}
              </IconButton>
            </Tooltip>
          </div>
          <div className='hover:scale-125 duration-200'>
            <Tooltip title='Excluir' arrow>
              <IconButton onClick={handleDeleteDialog}>
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPropertyCard;

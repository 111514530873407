import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const SearchFilters = ({ onSubmit }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedSearchResult } = useSelector((state) => state.objectValues);
  const [formValues, setFormValues] = useState(() => {
    // Initialize with current searchParams
    return Object.fromEntries(searchParams.entries());
  });
  
  const { register, handleSubmit, setValue, reset } = useForm({
    defaultValues: formValues
  });

  // Sync form values when selectedSearchResult changes
  useEffect(() => {
    if (selectedSearchResult?.properties?.filters) {
      const newValues = {};
      Object.entries(selectedSearchResult.properties.filters).forEach(([key, value]) => {
        if (key !== 'city' && key !== 'neighborhood' && key !== 'address' && value !== undefined && value !== null && value !== '') {
          newValues[key] = value;
        }
      });
      setFormValues(newValues);
      reset(newValues);
    } else {
      // Clear form if no selectedSearchResult
      setFormValues({});
      reset({});
    }
  }, [selectedSearchResult, reset]);

  const handleFieldChange = (e, fieldName) => {
    const value = e.target.value;
    
    setFormValues(prev => {
      const newValues = { ...prev };
      if (value === '' || value === 'any') {
        delete newValues[fieldName];
      } else {
        newValues[fieldName] = value;
      }
      return newValues;
    });

    setValue(fieldName, value);
    
    setSearchParams(prev => {
      if (value === '' || value === 'any') {
        prev.delete(fieldName);
      } else {
        prev.set(fieldName, value);
      }
      return prev;
    });
  };

  return (
    <form
      id='search-filters-form'
      className='flex flex-col items-center gap-6 overflow-y-auto'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex flex-col items-start gap-2'>
        <label className='w-32 text-emerald-700 font-medium'>
          Tipo de imóvel
        </label>
        <select
          {...register('mainType')}
          value={formValues.mainType || 'any'}
          onChange={(e) => handleFieldChange(e, 'mainType')}
          className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
        >
          <option value='any'>Qualquer</option>
          <option value='residential'>Residencial</option>
          <option value='commercial'>Comercial</option>
        </select>
      </div>
      <div className='flex flex-col items-start gap-2'>
        <label className='w-32 text-emerald-700 font-medium'>Estilo</label>
        {formValues.mainType === 'residential' ||
        formValues.mainType === 'any' ||
        formValues.mainType === null ? (
          <select
            {...register('type')}
            value={formValues.type || 'any'}
            onChange={(e) => handleFieldChange(e, 'type')}
            className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
          >
            <option value='any'>Qualquer</option>
            <option value='apartment'>Apartamento</option>
            <option value='house'>Casa</option>
            <option value='farm'>Sítio/Fazenda/Chácara</option>
            <option value='terrain'>Terreno/Lote</option>
          </select>
        ) : (
          <select
            {...register('type')}
            value={formValues.type || 'any'}
            onChange={(e) => handleFieldChange(e, 'type')}
            className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
          >
            <option value='any'>Qualquer</option>
            <option value='office'>Sala/Conjunto</option>
            <option value='store'>Ponto Comercial/Loja/Box</option>
            <option value='depot'>Depósito/Galpão/Armazém</option>
            <option value='clinic'>Consultório/Clínica</option>
            <option value='building'>Prédio/Edifício Inteiro</option>
            <option value='hotel'>Hotel/Motel/Pousada</option>
          </select>
        )}
      </div>
      <div className='flex flex-col items-start gap-2'>
        <label className='w-32 text-emerald-700 font-medium'>Valor (R$)</label>
        <div id='price' className='flex gap-2 xs:gap-2'>
          <input
            {...register('minPrice')}
            type='number'
            placeholder='Min'
            step={100}
            min={0}
            value={formValues.minPrice || ''}
            onChange={(e) => handleFieldChange(e, 'minPrice')}
            className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200  outline-emerald-500'
          />
          <input
            {...register('maxPrice')}
            type='number'
            placeholder='Max'
            step={100}
            min={0}
            value={formValues.maxPrice || ''}
            onChange={(e) => handleFieldChange(e, 'maxPrice')}
            className='w-20 h-7 p-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200  outline-emerald-500'
          />
        </div>
      </div>
      {selectedSearchResult?.geometry?.type !== 'Polygon' && (
        <div className='flex flex-col items-start gap-2'>
          <label className='w-32 text-emerald-700 font-medium'>
            Raio de busca
          </label>
          <select
            {...register('searchRadius')}
            value={formValues.searchRadius || 'any'}
            onChange={(e) => handleFieldChange(e, 'searchRadius')}
            className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
          >
            <option value='0.1'>100m</option>
            <option value='0.5'>500m</option>
            <option value='1'>1km</option>
            <option value='2'>2km</option>
            <option value='3'>3km</option>
          </select>
        </div>
      )}
      <div className='flex flex-col items-start gap-2'>
        <label className='w-32 text-emerald-700 font-medium'>
          Anunciado em
        </label>
        <select
          {...register('dateCreate')}
          value={formValues.dateCreate || 'any'}
          onChange={(e) => handleFieldChange(e, 'dateCreate')}
          className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
        >
          <option value='any'>Qualquer</option>
          <option value='1'>24 horas</option>
          <option value='3'>3 dias</option>
          <option value='7'>7 dias</option>
          <option value='14'>14 dias</option>
          <option value='30'>30 dias</option>
        </select>
      </div>
      <div className='flex flex-col items-start gap-2'>
        <label className='w-32 text-emerald-700 font-medium'>
          Portaria 24h
        </label>
        <select
          {...register('concierge')}
          value={formValues.concierge || 'any'}
          onChange={(e) => handleFieldChange(e, 'concierge')}
          className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
        >
          <option value='any'>Qualquer</option>
          <option value='true'>Sim</option>
          <option value='false'>Não</option>
        </select>
      </div>
      <div className='flex flex-col items-start gap-2'>
        <label className='w-32 text-emerald-700 font-medium'>Fiador</label>
        <select
          {...register('guarantor')}
          value={formValues.guarantor || 'any'}
          onChange={(e) => handleFieldChange(e, 'guarantor')}
          className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
        >
          <option value='any'>Qualquer</option>
          <option value='true'>Sim</option>
          <option value='false'>Não</option>
        </select>
      </div>
      <div className='flex flex-col items-start gap-2'>
        <label className='w-32 text-emerald-700 font-medium'>Caução</label>
        <select
          {...register('deposit')}
          value={formValues.deposit || 'any'}
          onChange={(e) => handleFieldChange(e, 'deposit')}
          className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
        >
          <option value='any'>Qualquer</option>
          <option value='true'>Sim</option>
          <option value='false'>Não</option>
        </select>
      </div>
      <div className='flex flex-col items-start gap-2'>
        <label className='w-32 text-emerald-700 font-medium'>Mobiliado</label>
        <select
          {...register('furnished')}
          value={formValues.furnished || 'any'}
          onChange={(e) => handleFieldChange(e, 'furnished')}
          className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
        >
          <option value='any'>Qualquer</option>
          <option value='true'>Sim</option>
          <option value='false'>Não</option>
        </select>
      </div>
      <div className='flex flex-col items-start gap-2'>
        <label className='w-32 text-emerald-700 font-medium'>Elevador</label>
        <select
          {...register('elevator')}
          value={formValues.elevator || 'any'}
          onChange={(e) => handleFieldChange(e, 'elevator')}
          className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
        >
          <option value='any'>Qualquer</option>
          <option value='true'>Sim</option>
          <option value='false'>Não</option>
        </select>
      </div>
      <div className='flex flex-col items-start gap-2'>
        <label className='w-32 text-emerald-700 font-medium'>Aceita pet</label>
        <select
          {...register('petFriendly')}
          value={formValues.pet || 'any'}
          onChange={(e) => handleFieldChange(e, 'pet')}
          className='w-40 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
        >
          <option value='any'>Qualquer</option>
          <option value='true'>Sim</option>
          <option value='false'>Não</option>
        </select>
      </div>
      <div className='flex flex-col items-start gap-2'>
        <label className='w-32 text-emerald-700 font-medium'>Área (m²)</label>
        <div id='area' className='flex gap-2 xs:gap-2'>
          <input
            {...register('minArea')}
            type='number'
            placeholder='Min'
            step={10}
            min={0}
            value={formValues.minArea || ''}
            onChange={(e) => handleFieldChange(e, 'minArea')}
            className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200  outline-emerald-500'
          />
          <input
            {...register('maxArea')}
            type='number'
            placeholder='Max'
            step={10}
            min={0}
            value={formValues.maxArea || ''}
            onChange={(e) => handleFieldChange(e, 'maxArea')}
            className='w-20 h-7 p-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200  outline-emerald-500'
          />
        </div>
      </div>
      <div className='flex flex-col items-start gap-2'>
        <label className='w-32 text-emerald-700 font-medium'>Quartos</label>
        <div id='bedrooms' className='flex gap-2 xs:gap-2'>
          <select
            {...register('minBedrooms')}
            value={formValues.minBedrooms || 'any'}
            onChange={(e) => handleFieldChange(e, 'minBedrooms')}
            className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
          >
            <option value='any'>Min</option>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
            <option value='6'>6</option>
          </select>
          <select
            {...register('maxBedrooms')}
            value={formValues.maxBedrooms || 'any'}
            onChange={(e) => handleFieldChange(e, 'maxBedrooms')}
            className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
          >
            <option value='any'>Max</option>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
            <option value='6'>6</option>
          </select>
        </div>
      </div>
      <div className='flex flex-col items-start gap-2'>
        <label className='w-32 text-emerald-700 font-medium'>Garagem</label>
        <div className='flex gap-2 xs:gap-2'>
          <select
            {...register('minParkingSlots')}
            value={formValues.minParkingSlots || 'any'}
            onChange={(e) => handleFieldChange(e, 'minParkingSlots')}
            className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
          >
            <option value='any'>Min</option>
            <option value='1'>0</option>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
            <option value='6'>6</option>
          </select>
          <select
            {...register('maxParkingSlots')}
            value={formValues.maxParkingSlots || 'any'}
            onChange={(e) => handleFieldChange(e, 'maxParkingSlots')}
            className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
          >
            <option value='any'>Max</option>
            <option value='1'>0</option>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
            <option value='6'>6</option>
          </select>
        </div>
      </div>
      <div className='flex flex-col items-start gap-2'>
        <label className='w-32 text-emerald-700 font-medium'>Banheiros</label>
        <div className='flex gap-2 xs:gap-2'>
          <select
            {...register('minBathrooms')}
            value={formValues.minBathrooms || 'any'}
            onChange={(e) => handleFieldChange(e, 'minBathrooms')}
            className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
          >
            <option value='any'>Min</option>
            <option value='1'>1 </option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
            <option value='6'>6</option>
          </select>
          <select
            {...register('maxBathrooms')}
            value={formValues.maxBathrooms || 'any'}
            onChange={(e) => handleFieldChange(e, 'maxBathrooms')}
            className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
          >
            <option value='any'>Max</option>
            <option value='1'>1 </option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
            <option value='6'>6</option>
          </select>
        </div>
      </div>
      <div className='flex flex-col items-start gap-2'>
        <label className='w-32 text-emerald-700 font-medium'>Suites</label>
        <div className='flex gap-2 xs:gap-2'>
          <select
            {...register('minSuites')}
            value={formValues.minSuites || 'any'}
            onChange={(e) => handleFieldChange(e, 'minSuites')}
            className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
          >
            <option value='any'>Min</option>
            <option value='1'>1 </option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
            <option value='6'>6</option>
          </select>
          <select
            {...register('maxSuites')}
            value={formValues.maxSuites || 'any'}
            onChange={(e) => handleFieldChange(e, 'maxSuites')}
            className='w-20 h-7 px-2 text-sm text-black duration-150 bg-slate-100 rounded-md hover:bg-slate-200 active:shadow-lg outline-emerald-500'
          >
            <option value='any'>Max</option>
            <option value='1'>1 </option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
            <option value='6'>6</option>
          </select>
        </div>
      </div>
    </form>
  );
};

export default SearchFilters;

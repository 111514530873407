import { useEffect, useState, React } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import './index.css';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import MovingServicePage from './pages/MovingServicePage';
import PhotoServicePage from './pages/PhotoServicePage';
import EvaluatePropertyServicePage from './pages/EvaluatePropertyServicePage';
import MyPropertiesPage from './pages/MyPropertiesPage';
import ProfilePage from './pages/ProfilePage';
import DrawnAreasPage from './pages/DrawnAreasPage';
import FavoritePropertiesPage from './pages/FavoritePropertiesPage';
import { onAuthStateChanged, updateProfile } from 'firebase/auth';
import { auth } from './firebase-config';
import { useDispatch } from 'react-redux';
import { loginValuesActions } from './store/loginValues';
import AccountConfigurationPage from './pages/AccountConfigurationPage';
import NavBar from './components/Layout/NavBar';
import DrawingModePage from './pages/DrawingModePage';
import AddPropertyPage from './pages/AddPropertyPage';
import { toast } from 'react-toastify';
import SearchResultsPage from './pages/SearchResultsPage';
import MyAlertsPage from './pages/MyAlertsPage';
import PropertyPage from './pages/PropertyPage';
import { VoiceSearchProvider } from './context/VoiceSearchContext';

const PageWrapper = () => (
  <VoiceSearchProvider>
    <div className='flex flex-col h-svh landscape:h-dvh'>
      <NavBar className='flex-none' />
      <div className='h-full'>
        <Outlet />
      </div>
    </div>
  </VoiceSearchProvider>
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <PageWrapper />,
    children: [
      { index: true, element: <HomePage /> },
      { path: 'mudar', element: <MovingServicePage /> },
      { path: 'fotografar', element: <PhotoServicePage /> },
      { path: 'avaliar', element: <EvaluatePropertyServicePage /> },
      { path: 'perfil', element: <ProfilePage /> },
      { path: 'minhas-propriedades', element: <MyPropertiesPage /> },
      { path: 'minhas-propriedades/adicionar', element: <AddPropertyPage /> },
      { path: 'favoritos', element: <FavoritePropertiesPage /> },
      { path: 'areas-desenhadas', element: <DrawnAreasPage /> },
      { path: 'configuracoes', element: <AccountConfigurationPage /> },
      { path: 'buscar', element: <SearchResultsPage /> },
      { path: 'meus-alertas', element: <MyAlertsPage /> },
      { path: 'imovel/:id', element: <PropertyPage /> },
      { path: 'desenhar', element: <DrawingModePage /> },

      { path: '*', element: <NotFoundPage /> },
    ],
  },
]);

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('User is logged in', user);
        user.getIdToken(true).then((token) => {
          localStorage.setItem('token', token);
          dispatch(loginValuesActions.setUserId(user.uid));
          dispatch(loginValuesActions.setUserName(user.displayName));
          dispatch(loginValuesActions.setUserEmail(user.email));
          const createdAt = new Date(user.metadata.creationTime);
          const lastLogin = new Date(user.metadata.lastSignInTime);
          dispatch(
            loginValuesActions.setUserCreatedAt(
              new Intl.DateTimeFormat('pt-BR', {
                dateStyle: 'short',
                timeStyle: 'short',
              }).format(createdAt)
            )
          );
          dispatch(
            loginValuesActions.setUserLastLogin(
              new Intl.DateTimeFormat('pt-BR', {
                dateStyle: 'short',
                timeStyle: 'short',
              }).format(lastLogin)
            )
          );
        });
        // localStorage.setItem('token', user.accessToken);
      } else {
        // console.log('User is not logged in');
        localStorage.removeItem('token');
        dispatch(loginValuesActions.resetLoginValues());
        // toast.error('Faça login para continuar');
      }
      return unsubscribe;
    });
  }, []);
  return <RouterProvider router={router} />;
};

export default App;
